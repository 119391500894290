import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, List, Typography, ListItemAvatar, ListItem, Avatar, ListItemText, Pagination } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata } from '../../data/data';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';

function Similerform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token, user_district, user_roles } = state.userInfo;
    const { title, admindiv, location, policestation, insdate, district, item_type } = props.item;
    const [loading, setLoading] = useState();
    const [complains, setComplains] = useState([]);
    const [page, setPage] = useState(0);
    const [totalpages, setTotalpages] = useState(0);
    const [size, setSize] = useState(20);
    const [count, setCount] = useState(0);
    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));


    useEffect(() => {
        fetchComplains(page, size, title, district, policestation, insdate, item_type);
        return () => { }
    }, []);

    const fetchComplains = async (page, size, searchkey, district, policestation, insdate, item_type) => {
        await axios.get(apidata.api + 'edritem/similer', { params: { page: page, size: size, searchkey: searchkey, district: district, policestation: policestation, insdate: insdate, item_type: item_type }, headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                if (response.status == 200) {
                    setComplains(response.data.rows);
                    setPage(response.data.currentPage);
                    setCount(response.data.count);
                    setTotalpages(response.data.totalPages);
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
            });
    }

    const handlePageChange = (event, value) => {
        const val = value - 1
        fetchComplains(val, size, title, district, policestation, insdate, item_type);
        setPage(val);
    }

    return (
        <Box sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            height: 500,
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
            <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>{t('possible_similar_complains')}</TitleTypograpy>
            {
                !complains.length && !loading && <p>No record found</p>
            }
            {loading && <p>Loading ...</p>}
            {
                complains.length && !loading && <Box sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 400,
                    overflow: "hidden",
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}><List>
                    {complains.map(c => {
                        return <>
                            <ListItem alignItems="flex-start" style={{ cursor: 'pointer' }}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#43ff33',
                                        }}
                                    >
                                        <EmojiPeopleIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={c.title} secondary={format(new Date(c.createdAt), 'MM/dd/yyyy HH:MM:SS')} />
                            </ListItem>

                        </>

                    })}
                </List>
                    <Pagination style={{ marginTop: 30 }} count={totalpages} color="secondary" page={page + 1} onChange={handlePageChange} />
                </Box>
            }

            <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                <CancelIcon style={{ marginRight: 10 }} />{t('close')}
            </Button>
        </Box>
    )
}

export default Similerform