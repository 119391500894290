import * as React from 'react';
import { Box, Button, Modal, TextField, Grid, Typography } from '@mui/material';
import FabButton from './FabButton';
import Imageuploader from '../forms/Imageuploader';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #4A148C',
  boxShadow: 24,
  p: 4,
};

const TitleTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: purple[900],
  fontVariant: 'bold',
  marginTop: 10,
  marginBottom: 10
}));

export default function Attachmentmodel(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t, i18n } = useTranslation();
  const item = {
    complain_title: props.item.title, 
    complain_id: props.item.id
  };

  return (
    <div>
      <FabButton color='#BA68C8' onClick={handleOpen} title={t('addattachmenet')}></FabButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item sm={12}><Typography variant='h5'>{t('add_attachments')}</Typography></Grid>
          <Typography variant='h6'>{props.item.id + '. ' + props.item.title}</Typography>
          <Grid container>
            <Grid item sm={12}>
              <Imageuploader item={item} />
            </Grid>
            <Grid item sm={8}></Grid>
            <Grid item sm={4}>
              <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={handleClose}>
                <Save style={{ marginRight: 10 }} />{t('close')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}