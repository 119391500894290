import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { HomePage } from "./pages/Home";
import { ProfilePage } from "./pages/Profile";
import { SettingsPage } from "./pages/Settings";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import { DashboardPage } from "./pages/Dashboard";
import { ComplainPage } from "./pages/Complain";
import { AllComplainsPage } from "./pages/AllComplains";
import { SearchPage } from "./pages/Search";
import { ReportsPage } from "./pages/Reports";
import { MessagesPage } from "./pages/Messages";
import { AllDuplicates } from "./pages/AllDuplicates";
import { AllTransferred } from "./pages/AllTransferred";
import {AllClosed} from "./pages/AllClosed";
import {AllPolice} from "./pages/AllPolice";
//24-08-30
import {AllComplainAdmin} from "./pages/AllComplainAdmin";
import { AdminDashboardPage } from "./pages/AdminDashboard";

 


export default function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="dashboard" element={<DashboardPage />} />
        <Route path="complain/appoval" element={<ComplainPage />} />
        <Route path="all_complains" element={<AllComplainsPage />} />
        <Route path="profile" element={<ProfilePage />} />
        <Route path="settings" element={<SettingsPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="reports" element={<ReportsPage />} />
        <Route path="messages" element={<MessagesPage />} />
        <Route path="duplicate" element={<AllDuplicates />} />
        <Route path="transferred" element={<AllTransferred />} />
        <Route path="all_closed" element={<AllClosed />} />
        <Route path="police_all" element={<AllPolice />} />
        {/* 24-08-30 */}
        <Route path="all_complains_admin" element={<AllComplainAdmin />} />
        <Route path="admin_dashbord" element={<AdminDashboardPage />} />

      </Route>
    </Routes>
  );
}
