import * as React from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import FabButton from './FabButton';
import Imageuploader from '../forms/Imageuploader';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import Policeassignform from './Policeassignform';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '0px solid #4A148C',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

export default function Policeassignmodel(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <FabButton color='#aa00ff' onClick={handleOpen} title={t('assign')}></FabButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Policeassignform close={handleClose} item={props.item} formclose={props.close}/>
        </Box>
      </Modal>
    </div>
  );
}