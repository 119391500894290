import React, { useEffect } from 'react'
import { Stack, List, Avatar, Box, Card, CardContent, Grid, ListItem, Typography } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

function ComplainDisplayBox(props) {

  useEffect(() => {

  }, []);

  return (
    <Card
      sx={{ height: '100%' }}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Stack>
              <Typography
                color="primary"
                variant="h5"
              >
                Complains
              </Typography>
              <Typography
              >
                Today/All
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {props.day}/{props.all}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: '#ff6f00',
                height: 56,
                width: 56
              }}
            >
              <EmojiPeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <List>
            <ListItem sx={{ margin: 0, padding: 0 }}>
              <Typography color="primary" sx={{ margin: 0, padding: 0 }} variant="caption">Total EC EDR</Typography>
              <Typography variant="body2" sx={{marginLeft:1}}>{props.allec}</Typography>
            </ListItem>
            <ListItem sx={{ margin: 0, padding: 0 }}>
              <Typography color="primary" sx={{ margin: 0, padding: 0 }} variant="caption">Total SL Police</Typography>
              <Typography variant="body2" sx={{marginLeft:1}}>{props.allpd}</Typography>
            </ListItem>
            <ListItem sx={{ margin: 0, padding: 0 }}>
              <Typography color="primary" sx={{ margin: 0, padding: 0 }} variant="caption">Today EC EDR</Typography>
              <Typography variant="body2" sx={{marginLeft:1}}>{props.dayec}</Typography>
            </ListItem>
            <ListItem sx={{ margin: 0, padding: 0 }}>
              <Typography color="primary" sx={{ margin: 0, padding: 0 }} variant="caption">Today SL Police</Typography>
              <Typography variant="body2" sx={{marginLeft:1}}>{props.daypd}</Typography>
            </ListItem>
          </List>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ComplainDisplayBox