import * as React from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import BoxButton from './BoxButton';
import Imageuploader from '../forms/Imageuploader';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import Editform from './Editform';
import { t } from 'i18next';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    border: '0px solid #4A148C',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
};


export default function Editmodel(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <BoxButton color='#4A148C' onClick={handleOpen} title={t('edit')}></BoxButton>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Editform close={handleClose} id={props.id} item={props.item} />
                </Box>
            </Modal>
        </div>
    );
}
