import React, { useState, useContext, useEffect } from 'react';
import { Fab, Grid, Typography, Paper } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Store } from '../../utils/Store';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import { apidata } from '../../data/data';

function Requestdetails(props) {
    const { state } = useContext(Store);
    const { id, token, user_district, location, user_roles } = state.userInfo;
    const [currentrole, setCurrentrole] = useState(user_roles[0]);
    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [complain, setComplain] = useState({});

    useEffect(() => {

        console.log(props.child.id);
        loadData(props.child.id);
        return () => {
        }
    }, []);

    const changePoliceStation = () => {

    };

    const loadData = async (id) => {
        setLoading(true);
        console.log(id);
        await axios.get(apidata.api + 'inquary/one', { params: { id: id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                if (response.status == 200) {
                    setComplain(response.data.data.details);
                    setLoaded(true);
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
            });
    }

    return (
        !loading && <>
            <NotificationContainer />
            <Paper  style={{ padding: '20px' }}>
                <Grid container >
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h6'>
                            {t('edr_request_title')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {props.child.title}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h6'>
                            {t('edr_request_desc')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {complain.description_en}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h6'>
                            {t('edr_file_ref')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {complain.file_ref}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h6'>
                            {t('edr_complain_date')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {'Date: ' + complain.createdAt }
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='h6'>
                            {t('edr_resmode')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {complain.res_mode}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={10}>
                        <Typography variant='h6'>
                            {t('edr_requester')}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {'Name: ' + complain.complainer_name_en}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {'Contact No: ' + complain.complainer_tp}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Typography variant='body'>
                            {'Address: ' + complain.complainer_address_en}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Requestdetails