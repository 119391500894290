import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';

function Duplicateform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [child, setChild] = useState({
        linkwith: '',
    });

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
      }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const kidSchema = yup.object({
        linkwith: yup
            .number(t('should_be_a_number'))
            .required(t('linked_item_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {
        axios.put(apidata.api + 'edritem/duplicate', {
            itemId: props.id,
            linkwith: data.linkwith,
            status: 'DUPLICATE',
            userId: id
        }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('status changed'), '');
                props.formclose();
            })
            .catch(error => {
                NotificationManager.error(t('error_data_save - ' + error), '');
                console.log(error);
            })
    }


    return (
        <Box>
            <NotificationContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item sm={12}><TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>{t('mark_as_duplicate')}</TitleTypograpy></Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginTop:1, marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('link_with')}
                            id='linkwith'
                            name='linkwith'
                            value={child.linkwith}
                            {...register('linkwith', { required: true })}
                            onChange={handleInputChange}
                            error={errors.linkwith}
                            helperText={errors.linkwith && errors.linkwith.message}
                        />
                    </Grid>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default Duplicateform