import React from 'react'
import { Fab } from '@mui/material';
import { useTranslation } from "react-i18next";

function FabButton(props) {
    const { t } = useTranslation();
    return (
        <Fab style={{ fontSize: 10, width:140, background:props.color, marginRight:10 }} color='primary' variant='extended' size='medium' onClick={() => { props.onClick(props.type) }} >

            {t(props.title)}
        </Fab>
    )
}

export default FabButton