export const TRANSLATIONS_EN = {
    site_name: 'EC-EDR',
    home: 'Home Welcome',
    login: 'Sign in',
    title_login: 'Welcome to the EC-EDR',
    sub_title_login: 'Election Commission of Sri Lanka',
    settings: 'Settings',
    profile: 'Profile',
    home_page: 'Home Page',
    welcome: 'Welcome',
    settings: 'Settings',
    profile: 'Profile',
    email_address: 'Email Address',
    password: 'Password',
    dont_have_acc_sign_up: "Don't have an account? Sign Up",
    email_required: 'Email is required',
    password_required: 'Password is required',
    password_length: 'Password should be of minimum 8 characters length',
    login_server_error: 'Sorry, Login failed please enter valid credentials',
    personal_details_title: 'Basic Personal  Details',
    log_out: 'Log Out',
    logged_in: 'Success, Welcome!',
    save_changes: 'Save Changes',
    verify: 'Verify',
    edr_complain_form_title: 'Details of the Complain',
    edr_complain_title: 'Title of The Complain ',
    edr_complain_desc: 'Description of The Complain ',
    edr_complain_location: 'Location of the Incident',
    edr_complain_date: 'Date of the Incident',
    edr_complain_time: 'Time of the Incident',
    edr_resmode: 'Received Mode',
    edr_req_file_ref: 'File Reference',
    attachments: 'Attachment',
    edr_more_location: 'Details of the Location',
    edr_attachments: 'Attachments',
    edr_complainer: 'Details of the Complainer',
    edr_requester: 'Details of the Requester',
    edr_approval_form_title: 'Details of The Approval Request ',
    edr_approval_title: 'Title of The Approval Request ',
    edr_approval_desc: 'Description of The Approval Request ',
    edr_district: 'District',
    edr_admin_div: 'Administrative Division',
    edr_close_police_station: 'Police Station',
    edr_police_div: 'Police Division',
    change_password: 'Change Password',
    call: 'Call',
    email: 'Email',
    fax: 'Fax',
    sms: 'SMS',
    date: 'Date',
    next: 'Save and Next',
    edr_complainer_name: 'Name',
    edr_complainer_name_primary: 'Name (Primary Complainer)',
    edr_complainer_name_secondary: 'Name (Scondary Complainer)',
    police_direct: 'SL Police (Direct)',
    edr_email: 'Email',
    edr_requester_name: 'Requester Name',
    edr_complainer_address: 'Address',
    edr_requester_address: ' Address',
    edr_complainer_mobile: ' Mobile',
    edr_requester_mobile: 'Requester Mobile',
    edr_approval_req_by: 'Request By',
    edr_approval_req_address: 'Address',
    edr_approval_req__mobile: 'Contact Number',
    complain_appoval: 'Complain/Approval',
    translate: 'Translation',
    translate: 'Translation',
    search: 'Search',
    reports: 'Reports',
    messages: 'Messages',
    select_role: 'Select Role',
    fullname_en: 'Full Name in English',
    fullname_si: 'Full Name in Sinhala',
    fullname_ta: 'Full Name in Tamil',
    dashboard: 'Dashboard',
    users: 'Users',
    roles: 'Roles',
    backup: 'Backups',
    circulars: 'Circulars',
    decision: 'Decisions',
    duplicates: 'Duplicates',
    no_records_available: 'No records available',
    complain_id: 'Complain id',
    add_image: 'Attach',
    print: 'Print',
    drop_item_here: 'Drop Item Here',
    upload_file: 'Upload File',
    preview: 'Preview',
    title_required: 'Title is required',
    description_required: 'Description is required',
    edr_approval_date: 'Received date',
    search_key: 'Search Key',
    edr_other_details_of_location: 'Other Details of the Location',
    edr_complain_category: 'Complain Category',
    edr_complain_severity: 'Complain Severity',
    other_details: 'Other Details',
    view_attachment: 'View Attachment',
    complain: 'Complain',
    request: 'Request',
    approvel_req: 'Approval Request',
    edr_complain: 'Details',
    data_saved: 'Success, Data saved',
    edr_inquary_title: 'Title of the Inquary',
    edr_inquary_desc: 'Description of the Inquary',
    edr_inquary_date: 'Date of the Inquary',
    edr_requester: 'Details of the Requester',
    add_comment: 'Add Comment',
    edr_comment_title: 'Title',
    edr_comment_desc: 'Description',
    assign_to: 'Assign To',
    assignee: 'Assignee',
    category: 'Category',
    severity_level: 'Severity Level',
    link_with: 'Link With',
    list_of_possible_complains: 'List of Possible Complains',
    verify: 'Verify',
    duplicate: 'Duplicate',
    change_center: 'Change Center',
    edr_center: 'EDR Center',
    comment: 'Comment',
    uploaded_images: 'Uploaded Images',
    addattachmenet: 'Add Attachmenet',
    addcomment: 'Add Comment',
    verify: 'Verify',
    viewsimilar: 'View Similar',
    closecomplain: 'Close Complain',
    complain_verify: 'Verify Complain',
    you_do_not_pocess_permission_to_perform_this_action: 'You do not pocess permission to perform this action',
    possible_similar_complains: 'Possible Similar Complains',
    attachment_title: 'Title of the Attachment',
    add_comment: 'Add Comment',
    add_attachments: 'Add Attachments',
    assign_complain: 'Assign Complain',
    add_closing_comment: 'Add Closing Comment',
    comment_required: 'Comment is required',
    complain_was_closed: 'Complain is closed',
    complain_not_closed: 'Complain is not closed',
    complain_assigned: 'Complain assigned',
    complain_assigned_not_assigned: 'Complain not assigned',
    duplicate: 'Duplicate',
    mark_as_duplicate: 'Mark as Duplicate',
    entered_by: 'Entered By ',
    on: 'at ',
    image_title: 'Title of the image',
    loading_report: 'Wait, Report is loading...',
    e1: 'Error, Password or Username not valid.',
    e2: 'Error, Given Email Address is not registerd.',
    e3: 'Error, Password is not valid',
    e4: 'Error, Could not find user role',
    mark_as_duplicate: 'Mark as duplicate',
    close_complain: 'Close Complain',
    comment_is_required: 'Comment is required',
    admin_division: 'Administrative Division: ',
    police_division: 'Police Division: ',
    police_station: 'Police Station: ',
    verified: 'Verified',
    not_verified: 'Not Verified',
    complain_to: 'Complain To',
    complain_from: 'Complain From',
    complain_from_required: 'Complain from required',
    complain_to_required: 'Complain to required',
    edr_received_date: 'Received Data and Time',
    otp: 'OTP',
    current_password: 'Current Password',
    new_password: 'New Password',
    re_new_password: 'Re Enter New Password',
    otp_required: 'OTP Required',
    name_is_required: 'Name is required',
    nic_is_required: 'NIC number is required',
    address_is_required: 'Address is required',
    current_password_required: 'Current Password Required',
    new_password_required: 'New Password Required',
    re_enter_password: 'Re Enter New Password',
    password_changed: 'Success, Password was changed',
    addsuspects: 'Add Suspects',
    add_suspects: 'Add Suspects',
    add_progress: 'Progress of the Inquary',
    add_court_prceedings: 'Add Court Proceedings',
    court_proceeding: 'Court Proceedings',
    full_name: 'Full Name',
    address: 'Address',
    nic: 'NIC Number',
    political_affiliation: 'Political Affiliation',
    is_candidate: 'Is Candidate',
    court_name: 'Name of the Court',
    b_report_number: 'B-report Number',
    plaint_number: 'Plaint Number',
    court_action: 'Court Action',
    bailed: 'Bailed',
    remanded: 'Remanded',
    brief_note: 'Brief Note',
    rank: 'Rank',
    inquary_officer_name: 'Inquary Officer',
    intending_to_take_legal_action: 'Intending to take legal action ',
    case_settled_by_the_police: 'Case Settled by the Police',
    suspect_interrogated_and_released: 'Suspect/s interrogated and released',
    suspect_added: 'Success, Suspect added',
    not_saved: 'Error, Data is not saved',
    num_sus_arrested: 'Number of suspects arrested',
    progress_saved: 'Progress is saved',
    court_date: 'Court Date',
    next_court_date: 'Next Court Date',
    court_name_is_required: 'Court name is required',
    court_progress_added: 'Court progrss added',
    b_report_no: 'B Report No.',
    court: 'Court Name',
    plaint_no: 'Plaint No.',
    suspect_action: 'Suspects Action',
    add_victim: 'Add Victims',
    //victim form & modal
    victim: 'Victim',
    victim_details: 'Details of Victim',
    victim_name: 'Full Name',
    victim_name_is_required: 'Victim Name required',
    victim_address: 'Address',
    victim_address_is_required: 'Victim Address required',
    victim_nic: 'NIC Number',
    victim_nic_is_required: 'Victim NIC required',
    political_affiliation: 'Political affiliation',
    political_affiliation_is_required: 'Political affiliation required',
    save: 'Save',
    clear: 'Clear',
    iscandidate: 'is candidate',
    //damagedproperty form & modal
    damagedproperty: 'Damaged Property',
    damaged_property: 'Whether Damaged Property',
    sector_type: 'Type of Sector',
    assest_type: 'Type of Assest',
    sector_is_required: 'Type of Sector required',
    assest_is_required: 'Type of Assest required',
    property_value: 'Value of the Property: (RS)',
    others: 'Others',
    //propertycustody form & modal
    propertycustody: 'Property Custody',
    property_custody: 'Details of Property Custodied By the Police',
    typeofproperty: 'Type of Property',
    amount_property: 'Amount of the Property: (g/Kg)',
    numberof_property: 'Number of Property',
    vehicle_reg_no: 'Vehicle Register Number',
    vehi_category: ' Vehicle Category',
    vehicategory_is_required: 'Vehicle category_is_required',
    typeofproperty_is_required: 'Type of proprty is required',
    amount_property_required: 'Amount of Property is required',
    numberof_property_is_required: 'Number of Property is required ',
    vehicle_reg_no_is_required: 'Vehicle Register No is required',
    remarks: 'Remarks',
    ec_ref: 'EC Reference Number',
    edr_request_form_title: 'Details of the Request',
    edr_request_title: 'Title of the Request',
    edr_request_desc: 'Description of the Request',
    edr_request_date: 'Date of the Request',
    edr_file_ref: 'File Reference',
    all_police: 'All Police Assigned',

    // edit button
    title: 'Complain Title',
    description: 'Complain Description',
    edit: 'Edit',
    update: 'Update',
    complain_edit_success: "Complain  Successfully",
    complain_edit_not_saved: 'Complain Not Saved',

}