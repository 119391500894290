import { PoliceAllPage } from "../components/PoliceAllPage";
import Home from "@mui/icons-material/Home";
import '../locales/i18n';
import { useTranslation } from "react-i18next";




export const AllPolice = () => {
  const { t } = useTranslation();
 
  return <PoliceAllPage title={t('all_police')} icon={<Home />} />;
};


