import Home from "@mui/icons-material/Home";
import '../locales/i18n';
import { useTranslation } from "react-i18next";
import { DuplicatesAllPage } from "../components/DuplicatesAllPage";




export const AllDuplicates = () => {
  const { t } = useTranslation();
 
  return <DuplicatesAllPage title={t('duplicates')} icon={<Home />} />;
};
