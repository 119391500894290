import { AdminComplainAllPage } from "../components/AdminComplainAllPage";

import Home from "@mui/icons-material/Home";
import '../locales/i18n';
import { useTranslation } from "react-i18next";




export const AllComplainAdmin = () => {
  const { t } = useTranslation();
 
  return <AdminComplainAllPage title={t('all_complains_admin')} icon={<Home />} />;
};
