import * as React from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import FabButton from './FabButton';
import Imageuploader from '../forms/Imageuploader';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";

function Attachmentform(props) {
    const { t, i18n } = useTranslation();
    return (
        <Box>
            <from>
                <Grid container>
                    <Grid item sm={12}><Typography varient='h5'>Add an Attachment</Typography></Grid>
                    <Grid item sm={12}>
                        <Imageuploader item={props.item}/>
                    </Grid>
                    <Grid item sm={8}>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                            <Save style={{ marginRight: 10 }} />{t('close')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('save')}
                        </Button>
                    </Grid>
                </Grid>
            </from>
        </Box>
    )
}

export default Attachmentform