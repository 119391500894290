import { Step, StepLabel, Stepper } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";

function Complainwizard({activeStep=0}) {
    const { t } = useTranslation();
    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {[t('edr_complain'), t('edr_attachments')].map(
                (step) => {
                    return <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                    </Step>
                }
            )}
        </Stepper>
    )
}

export default Complainwizard
