import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';


function Editform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [child, setChild] = useState({
        title: props.item.title,
        description: props.item.description,
        itemId: props.item.itemId,
        item_type: props.item.item_type,


    });




    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const kidSchema = yup.object({
        title: yup
            .string('')
            .required(t('complain_title_is_required')),
        description: yup
            .string('')
            .required(t('complain_description_is_required'))
    });


    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });




    const onSubmit = (data) => {


        axios.put(apidata.api + 'edritem/update',
            {
                title: data.title,
                description: data.description,
                itemId: props.id,
                item_type: props.item.item_type,
                userId: id
            },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('complain_edit_success'), '');
                props.close();
            })
            .catch(error => {
                NotificationManager.error(t('complain_edit_not_saved') + error.code, '');
            })
    }




    return (
        <Box>
            <NotificationContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item sm={12}><TitleTypograpy sx={{ marginBottom: 2 }}>Edit complain</TitleTypograpy></Grid>
                    <Grid item sm={12}>
                        <TextField
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 1, backgroundColor: '#fff',
                            }}
                            multiline
                            minRows={1}
                            maxRows={2}
                            fullWidth
                            label={t('title')}
                            id='title'
                            name='title'
                            {...register('title', { required: true })}
                            value={child.title}
                            onChange={handleInputChange}
                            error={errors.title}
                            helperText={errors.title && errors.title.message}
                        />
                    </Grid>


                    <Grid item sm={12}>
                        <TextField
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 1, backgroundColor: '#fff',
                            }}
                            multiline
                            minRows={5}
                            maxRows={5}
                            fullWidth
                            label={t('description')}
                            id='description'
                            name='description'
                            {...register('description', { required: true })}
                            value={child.description}
                            onChange={handleInputChange}
                            error={errors.description}
                            helperText={errors.description && errors.description.message}
                        />
                    </Grid>


                    <Grid item sm={6}></Grid>
                    <Grid item sm={6}>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit" >
                            <Save style={{ marginRight: 10 }} />{t('update')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </form>
        </Box>
    )
}


export default Editform

