// import React, { useState, useEffect, useContext } from 'react'
// import { Box, Button, Grid, TextField } from "@mui/material";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { useForm } from "react-hook-form";
// import { Save } from "@mui/icons-material";
// import { useTranslation } from "react-i18next";
// import axios from 'axios';
// import { Store } from '../../utils/Store';
// import { apidata } from '../../data/data';
// import Stack from "@mui/material/Stack";
// import Divider from '@mui/material/Divider';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import { NotificationManager } from 'react-notifications';
// import { purple } from '@mui/material/colors';


// function Imageuploader(props) {
//     const { t } = useTranslation();
//     const { state } = useContext(Store);
//     const { id, token } = state.userInfo;
//     //const { register, handleSubmit } = useForm();
//     const [selectedFile, setSelectedFile] = useState();
//     const { complain_title, complain_id } = props.item;
//     const [images, setImages] = useState([]);
//     const [preview, setPreview] = useState();
//     const [comment, setComment] = useState('');


//     const imageSchema = yup.object({
//         comment: yup
//             .string('')
//             .required(t('comment_required')),
//         file: yup
//             .mixed()
//             .required('File is required'),
//     });


//     const { register, handleSubmit, formState: { errors } } = useForm({
//         resolver: yupResolver(imageSchema)
//     });

//     const onSubmit = async (data) => {
//         const api = apidata.api;
//         try {
//             const formData = new FormData();
//             if (selectedFile !== null) {
//                 formData.append("edr_img", selectedFile);
//                 formData.append("title", comment);
//                 formData.append("user_id", id);
//                 formData.append("item_id", props.item.complain_id);
//                 console.log('---------' + formData);
//                 const APIURL = api + "uploadimage";
//                 await axios.post(
//                     APIURL,
//                     formData,
//                     { headers: { "Authorization": `Bearer ${token}` } })
//                     .then(x => {
//                         setImages(images => [...images, selectedFile]);
//                         setSelectedFile(null);
//                         setPreview(null);
//                         setComment('');
//                     }).catch(err => {
//                         alert(err.message);
//                     })
//             } else {
//                 NotificationManager.error(t('Error, Attachedment is not available'), '');
//             }
//         } catch (err) {
//             NotificationManager.error(err.message, '');
//         }

//     };

//     useEffect(() => {
//         if (!selectedFile) {
//             setPreview(undefined)
//             return
//         }
//         const objectUrl = URL.createObjectURL(selectedFile)
//         setPreview(objectUrl)
//         // free memory when ever this component is unmounted
//         return () => URL.revokeObjectURL(objectUrl)
//     }, [selectedFile]);

//     const onSelectFile = e => {
//         if (!e.target.files || e.target.files.length === 0) {
//             setSelectedFile(undefined)
//             return
//         }
//         setSelectedFile(e.target.files[0])
//     }

//     const handleInputChange = (event) => {
//         const { value } = event.target;
//         setComment(value);

//     };

//     return (
//         <Box sx={{
//             minWidth: 800,
//             marginTop: 3,
//             marginBottom: 2,
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center"
//         }}>
//             <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
//                 <Box sx={{
//                     background: '#f1f1f1', padding: 2, border: 1, borderColor:purple[200],  borderRadius: 1 
//                 }}>
//                     <Stack justifyContent="center"
//                         alignItems="center">
//                         <form onSubmit={handleSubmit(onSubmit)}>
//                             <TextField
//                                 sx={{
//                                     "& .MuiInputBase-root": {
//                                         height: 40,
//                                         backgroundColor: '#fff',
//                                         marginBottom: 1
//                                     }
//                                 }}
//                                 fullWidth
//                                 label={t('image_title')}
//                                 id='comment'
//                                 name='comment'
//                                 {...register('comment', { required: true })}
//                                 value={comment}
//                                 onChange={handleInputChange}
//                                 error={errors.comment}
//                                 helperText={errors.comment && errors.comment.message}
//                             />
//                             <input type='file' id='file' name='file'{...register("file")} onChange={onSelectFile} />
//                             {errors.file && <p>{errors.file.message}</p>}
//                             <Button sx={{ margin: 1, marginBottom: 2 }} variant="contained" color="primary" type="submit">
//                                 <Save style={{ marginRight: 10 }} />{t('save')}
//                             </Button>
//                         </form>
//                         {selectedFile && <img width={200} height={200} src={preview} alt='' />}
//                         {!selectedFile && <img width={200} height={200} src={'http://localhost:3024/preview/no-preview.png'} alt='' />}
//                     </Stack>
//                 </Box>
//                 <Box style={{ padding: 10 }}>
//                     <ImageList sx={{ width: 500, height: 350 }} cols={3} rowHeight={164}>
//                         {images ? images.map((item) => {
//                             console.log(item);
//                             const imgurl = URL.createObjectURL(item);
//                             return <ImageListItem key={imgurl} sx={{ margin: 1 }}>
//                                 <img
//                                     src={imgurl}
//                                     width={100}
//                                     height={100}
//                                     srcSet={imgurl}
//                                     loading="lazy"
//                                 />
//                             </ImageListItem>
//                         }) : ''}
//                     </ImageList>
//                 </Box>
//             </Stack>
//         </Box>
//     )
// }

// export default Imageuploader



import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, TextField } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { Save } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata } from '../../data/data';
import Stack from "@mui/material/Stack";
import Divider from '@mui/material/Divider';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { NotificationManager } from 'react-notifications';
import { purple } from '@mui/material/colors';

function ImageUploader(props) {
    const { t } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [selectedFile, setSelectedFile] = useState(null);
    const { complain_title, complain_id } = props.item;
    const [images, setImages] = useState([]);
    const [preview, setPreview] = useState();
    const [comment, setComment] = useState('');

    const imageSchema = yup.object({
        comment: yup
            .string()
            .required(t('comment_required')),
        file: yup
            .mixed()
            .required(t('file_required'))
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(imageSchema)
    });

    const onSubmit = async (data) => {
        const api = apidata.api;
        const formData = new FormData();

        if (selectedFile) {
            formData.append("edr_img", selectedFile);
            formData.append("title", comment);
            formData.append("user_id", id);
            formData.append("item_id", props.item.complain_id);

            try {
                const APIURL = `${api}uploadimage`;
                const response = await axios.post(APIURL, formData, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "multipart/form-data"
                    }
                });

                // Success Handling
                setImages(images => [...images, selectedFile]);
                setSelectedFile(null);
                setPreview(null);
                setComment('');
                NotificationManager.success(t('Image uploaded successfully'), '');
            } catch (err) {
                // Error Handling
                if (err.response) {
                    NotificationManager.error(err.response.data.message, '');
                } else {
                    NotificationManager.error(t('An error occurred while uploading the image'), '');
                }
            }
        } else {
            NotificationManager.error(t('Error, Attachment is not available'), '');
        }
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // Free memory when the component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        setSelectedFile(e.target.files[0]);
    };

    const handleInputChange = (event) => {
        setComment(event.target.value);
    };

    return (
        <Box sx={{
            minWidth: 800,
            marginTop: 3,
            marginBottom: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        }}>
            <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
                <Box sx={{
                    background: '#f1f1f1', padding: 2, border: 1, borderColor: purple[200], borderRadius: 1
                }}>
                    <Stack justifyContent="center" alignItems="center">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                sx={{
                                    "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff',
                                        marginBottom: 1
                                    }
                                }}
                                fullWidth
                                label={t('image_title')}
                                id='comment'
                                name='comment'
                                {...register('comment')}
                                value={comment}
                                onChange={handleInputChange}
                                error={!!errors.comment}
                                helperText={errors.comment?.message}
                            />
                            <input type='file' id='file' name='file' {...register("file")} onChange={onSelectFile} />
                            {errors.file && <p>{errors.file.message}</p>}
                            <Button sx={{ margin: 1, marginBottom: 2 }} variant="contained" color="primary" type="submit">
                                <Save style={{ marginRight: 10 }} />{t('save')}
                            </Button>
                        </form>
                        {selectedFile && <img width={200} height={200} src={preview} alt='' />}
                        {!selectedFile && <img width={200} height={200} src={'http://localhost:3024/preview/no-preview.png'} alt='' />}
                    </Stack>
                </Box>
                <Box style={{ padding: 10 }}>
                    <ImageList sx={{ width: 500, height: 350 }} cols={3} rowHeight={164}>
                        {images.map((item, index) => {
                            const imgurl = URL.createObjectURL(item);
                            return (
                                <ImageListItem key={index} sx={{ margin: 1 }}>
                                    <img
                                        src={imgurl}
                                        width={100}
                                        height={100}
                                        loading="lazy"
                                        alt=""
                                    />
                                </ImageListItem>
                            );
                        })}
                    </ImageList>
                </Box>
            </Stack>
        </Box>
    );
}

export default ImageUploader;