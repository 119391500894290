import React, { useState, useEffect, useContext } from 'react';
import { TableBody, Box, Button, Grid, TextField, Typography, FormControlLabel, Switch, Divider, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { green, purple } from '@mui/material/colors';

function Suspectsform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [child, setChild] = useState({
        sus_name: '',
        address: '',
        nic: '',
        political_affiliation: ''
    });
    const [iscandidate, setIscandidate] = useState(false);
    const [val, setVal] = useState(0);
    const [suspects, setSuspects] = useState([]);

    useEffect(() => {
        loadData();
    }, [val]);

    const loadData = () => {
        axios.get(apidata.api + 'suspect/viaitem', { params: { id: props.id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log(data.data.data)
                setSuspects(data.data.data);
            })
            .catch(error => {
                setSuspects([]);
                //NotificationManager.error(t('po_load_error'), '');
            })
    }


    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const kidSchema = yup.object({
        sus_name: yup
            .string('')
            .required(t('name_is_required')),
        // nic: yup
        //     .string('')
        //     .required(t('nic_is_required')),
        address: yup
            .string('')
            .required(t('address_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });

    const handleSwitchChange = (event) => {
        const { name, value } = event.target;
        setIscandidate(!iscandidate);
    }

    const resetForm = () => {
        setChild({
            sus_name: '',
            address: '',
            nic: '',
            political_affiliation: '',
        });
        setIscandidate(false);
    }

    const onSubmit = (data) => {
        axios.post(apidata.api + 'suspect',
            { sus_name: data.sus_name, address: data.address, nic: data.nic, pf: data.political_affiliation, is_can: iscandidate, itemId: props.id, userId: id },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('suspect_added'), '');
                //props.close();
                setVal(val + 1);
            })
            .catch(error => {
                NotificationManager.error(t('not_saved. ') + error.message, '');
            })
    }


    return (
        <Box sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            height: 600,
            overflowX: "scroll",
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
            <NotificationContainer />
            <Box >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('add_suspects')}</TitleTypograpy></Grid>
                        <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />
                        </Button></Grid>
                        <Grid item sm={11} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('full_name')}
                                id='sus_name'
                                name='sus_name'
                                {...register('sus_name', { required: true })}
                                value={child.sus_name}
                                onChange={handleInputChange}
                                error={errors.sus_name}
                                helperText={errors.sus_name && errors.sus_name.message}
                            />
                        </Grid>
                        <Grid item sm={11} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('address')}
                                id='address'
                                name='address'
                                {...register('address', { required: true })}
                                value={child.address}
                                onChange={handleInputChange}
                                error={errors.address}
                                helperText={errors.address && errors.address.message}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('nic')}
                                id='nic'
                                name='nic'
                                {...register('nic', { required: true })}
                                value={child.nic}
                                onChange={handleInputChange}
                                error={errors.nic}
                                helperText={errors.nic && errors.nic.message}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 2, marginRight: 2, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('political_affiliation')}
                                id='political_affiliation'
                                name='political_affiliation'
                                {...register('political_affiliation', { required: true })}
                                value={child.political_affiliation}
                                onChange={handleInputChange}
                                error={errors.political_affiliation}
                                helperText={errors.political_affiliation && errors.political_affiliation.message}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12} sx={{ marginLeft: 5, }}>
                            <FormControlLabel control={<Switch id='iscandidate' name='iscandidate' checked={iscandidate} onChange={handleSwitchChange} />} label={t('is_candidate')} />
                        </Grid>
                        <Grid item sm={8}></Grid>
                        <Grid item sm={4}>
                            <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={resetForm}>
                                <RestartAltIcon style={{ marginRight: 10 }} />{t('Clear')}
                            </Button>
                            <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                                <Save style={{ marginRight: 10 }} />{t('save')}
                            </Button>
                        </Grid>
                        <Grid item sm={2}></Grid>
                        <Grid item sm={12}><Divider sx={{ marginBottom: 3, marginTop: 2, backgroundColor: purple[900] }}></Divider></Grid>
                    </Grid>
                </form>
            </Box>
            <Box
                sx={{
                    mb: 2,
                    height: 500,
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('nic')}</TableCell>
                            <TableCell>{t('full_name')}</TableCell>
                            <TableCell>{t('address')}</TableCell>
                            <TableCell >{t('political_affiliation')}</TableCell>
                            <TableCell >{t('is_candidate')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {suspects && suspects.map(s => {
                            return <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {s.nic}
                                </TableCell>
                                <TableCell> {s.sus_name}</TableCell>
                                <TableCell > {s.address}</TableCell>
                                <TableCell > {s.pol_aff}</TableCell>
                                <TableCell > {s.is_candidate?'Yes':'No'}</TableCell>
                            </TableRow>
                        })}

                    </TableBody>
                </Table>
            </Box>
        </Box>
    )
}

export default Suspectsform