import React, { useState, useEffect, useContext } from 'react';
import {Table, Divider, TableBody,TableHead, TableRow, TableCell, Box, Button, Grid, TextField, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function Courtform(props) {
    const date = new Date();
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [formatedDate, setFormatedDate] = useState(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`);
    const [child, setChild] = useState({
        court_name: '',
        b_report_number: '',
        plaint_number: '',
        court_date: '',
        brief_note: '',
        next_court_date: '',
    });
    const [bailed, setBailed] = useState('Bailed');
    const [records, setRecords] = useState([]);
    const [val, setVal] = useState(0);

    useEffect(() => {
        loadData();
    }, [val])
    

    const loadData = () => {
        axios.get(apidata.api + 'courtprogress/viaitem', { params: { id: props.id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log(data.data.data)
                setRecords(data.data.data);
            })
            .catch(error => {
                setRecords([]);
                //NotificationManager.error(t('po_load_error'), '');
            })
    }

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBailedChange = (event) => {
        const { name, value } = event.target;
        setBailed(value);
    }

    const kidSchema = yup.object({
        court_name: yup
            .string('')
            .required(t('court_name_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {
        axios.post(apidata.api + 'courtprogress',
            { 
                court_name: data.court_name, 
                b_report_number: data.b_report_number, 
                plaint_number: data.plaint_number, 
                court_date: data.court_date, 
                brief_note: data.brief_note, 
                next_court_date: data.next_court_date, 
                bailed: bailed, 
                itemId: props.id, 
                userId: id },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('court_progress_added'), '');
                props.close();
            })
            .catch(error => {
                NotificationManager.error(t('not_saved. ') + error.code, '');
            })
    }


    return (
        <Box sx={{
            mb: 2,
            height: 500,
            overflowY: "scroll",
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
            <NotificationContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('add_court_prceedings')}</TitleTypograpy></Grid>
                    <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                        <CancelIcon style={{ marginRight: 10 }} />
                    </Button></Grid>
                    <Grid item sm={11} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('court_name')}
                            id='court_name'
                            name='court_name'
                            {...register('court_name', { required: true })}
                            value={child.court_name}
                            onChange={handleInputChange}
                            error={errors.court_name}
                            helperText={errors.court_name && errors.court_name.message}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('b_report_number')}
                            id='b_report_number'
                            name='b_report_number'
                            {...register('b_report_number', { required: true })}
                            value={child.b_report_number}
                            onChange={handleInputChange}
                            error={errors.b_report_number}
                            helperText={errors.b_report_number && errors.b_report_number.message}
                        />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 3, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('plaint_number')}
                            id='plaint_number'
                            name='plaint_number'
                            {...register('plaint_number', { required: true })}
                            value={child.plaint_number}
                            onChange={handleInputChange}
                            error={errors.plaint_number}
                            helperText={errors.plaint_number && errors.plaint_number.message}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12} style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                        <TextField
                            type="date"
                            sx={{
                                marginLeft: 4, marginRight: 10, marginBottom: 0, "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            fullWidth
                            label={t('court_date')}
                            id='court_date'
                            name='court_date'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register('court_date', { required: true })}
                            defaultValue={formatedDate}
                            value={child.court_date}
                            onChange={handleInputChange}
                            error={errors.court_date}
                            helperText={errors.court_date && errors.court_date.message}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <FormControl sx={{marginLeft: 5}}>
                            <InputLabel id="demo-simple-select-label" sx={{
                                minWidth: 200
                            }}>{t('court_action')}</InputLabel>
                            <Select
                                sx={{
                                    minWidth: 200,maxHeight: 40,  marginBottom: 0, "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                name='bailed'
                                fullWidth
                                id='court_action'
                                {...register('court_action', { required: true })}
                                error={errors.bailed}
                                helperText={errors.court_action && errors.court_action.message}
                                value={bailed}
                                onChange={handleBailedChange}
                            >
                                <MenuItem value='Bailed' style={{ fontSize: 12 }}>{t('bailed')}</MenuItem>
                                <MenuItem value='Remanded' style={{ fontSize: 12 }}>{t('remanded')}</MenuItem>
                            </Select>


                        </FormControl>
                    </Grid>

                    <Grid item sm={11}>
                        <TextField
                            sx={{
                                marginRight: 10, marginTop: 2, marginLeft: 1, marginBottom: 1, backgroundColor: '#fff',
                            }}
                            multiline
                            minRows={3}
                            maxRows={3}
                            fullWidth
                            label={t('brief_note')}
                            id='brief_note'
                            name='brief_note'
                            {...register('brief_note', { required: true })}
                            value={child.brief_note}
                            onChange={handleInputChange}
                            error={errors.brief_note}
                            helperText={errors.brief_note && errors.brief_note.message}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12} style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                        <TextField
                            type="date"
                            sx={{
                                marginTop:2, marginLeft: 0, marginRight: 10, marginBottom: 0, "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            fullWidth
                            label={t('next_court_date')}
                            id='next_court_date'
                            name='next_court_date'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register('next_court_date', { required: true })}
                            defaultValue={formatedDate}
                            value={child.next_court_date}
                            onChange={handleInputChange}
                            error={errors.next_court_date}
                            helperText={errors.next_court_date && errors.next_court_date.message}
                        />
                    </Grid>
                    <Grid item sm={9}></Grid>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={6}>
                        <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={props.close}>
                            <RestartAltIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('save')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={12}><Divider sx={{ marginBottom: 3, marginTop: 2, backgroundColor: purple[900] }}></Divider></Grid>
                   
                </Grid>
            </form>
            <Box
                sx={{
                    mb: 2,
                    height: 200,
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('court')}</TableCell>
                            <TableCell>{t('b_report_no')}</TableCell>
                            <TableCell>{t('plaint_no')}</TableCell>
                            <TableCell >{t('date')}</TableCell>
                            <TableCell >{t('suspect_action')}</TableCell>
                            <TableCell >{t('next_court_date')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records && records.map(s => {
                            return <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {s.court_name}
                                </TableCell>
                                <TableCell> {s.b_report_number}</TableCell>
                                <TableCell > {s.plaint_number}</TableCell>
                                <TableCell > {s.court_date}</TableCell>
                                <TableCell > {s.bailed}</TableCell>
                                <TableCell > {s.next_court_date}</TableCell>
                            </TableRow>
                        })}

                    </TableBody>
                </Table>
            </Box>
        </Box>
    )
}

export default Courtform