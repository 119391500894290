import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box, Button, Grid, Typography, Switch, FormControlLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Victimform(props) {
    const { t } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [iscandidate, setIscandidate] = useState(false);
    const [val, setVal] = useState(0);
    const [victim, setVictim] = useState([]);

    const [child, setChild] = useState({
        victim_name: '',
        victim_address: '',
        victim_nic: '',
        political_affiliation: '',
        iscandidate: '',
    });

    useEffect(() => {
        loadData();
    }, [val]);

    const loadData = () => {
        axios.get(apidata.api + 'victim/victim', { params: { id: props.id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log(data.data.data);
                setVictim(data.data.data);
            })
            .catch(error => {
                setVictim([]);
            })
    }

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const refresh = (event) => {
        setChild({
            victim_name: '',
            victim_address: '',
            victim_nic: '',
            political_affiliation: '',

        });
        setIscandidate(false);

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSwitchChange = (event) => {
        // const { name, value } = event.target;
        setIscandidate(!iscandidate)
    }

    const kidSchema = yup.object({
        victim_name: yup
            .string()
            .required(t('victim_name_is_required')),
        victim_address: yup
            .string()
            .required(t('victim_address_is_required')),
        // victim_nic: yup
        //     .string()
        //     .required(t('victim_nic_is_required')),
        // political_affiliation: yup
        //     .string()
        //     .required(t('political_affiliation_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {
        axios.post(apidata.api + 'victim', {
            itemId: props.id,
            victim_name: data.victim_name,
            victim_address: data.victim_address,
            victim_nic: data.victim_nic,
            political_affiliation: data.political_affiliation,
            victim_is_candidate: iscandidate,
            created_by: id
        }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('Success, Details of victim'), '');
                setVal(val + 1);
                // props.close();
                // props.formclose();
            })
            .catch(error => {
                NotificationManager.error(error.message, '');
            })
    }

    return (
        <Box
            sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: 600,
                overflowX: "scroll",
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
            }}>
            <NotificationContainer />
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('victim_details')}</TitleTypograpy></Grid>
                        <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />
                        </Button></Grid>
                        <Grid item sm={11} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('victim_name')}
                                id='victim_name'
                                name='victim_name'
                                {...register('victim_name', { required: true })}
                                value={child.victim_name}
                                onChange={handleInputChange}
                                error={errors.victim_name}
                                helperText={errors.victim_name && errors.victim_name.message}
                            />

                        </Grid>
                        <Grid item sm={11} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('victim_address')}
                                id='victim_address'
                                name='victim_address'
                                {...register('victim_address', { required: true })}
                                value={child.victim_address}
                                onChange={handleInputChange}
                                error={errors.victim_address}
                                helperText={errors.victim_address && errors.victim_address.message}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12} >
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('victim_nic')}
                                id='victim_nic'
                                name='victim_nic'
                                {...register('victim_nic', { required: true })}
                                value={child.victim_nic}
                                onChange={handleInputChange}
                                error={errors.victim_nic}
                                helperText={errors.victim_nic && errors.victim_nic.message}
                            />
                        </Grid >
                        <Grid item sm={4} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    marginRight: 2, marginLeft: 2, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('political_affiliation')}
                                id='political_affiliation'
                                name='political_affiliation'
                                {...register('political_affiliation', { required: true })}
                                value={child.political_affiliation}
                                onChange={handleInputChange}
                                error={errors.political_affiliation}
                                helperText={errors.political_affiliation && errors.political_affiliation.message}
                            />
                        </Grid >

                        <Grid sm={3} xs={12}
                            sx={{
                                marginRight: 2, marginLeft: 4, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}>

                            <FormControlLabel control={<Switch id='iscandidate' name='iscandidate' value='iscandidate' checked={iscandidate} onChange={handleSwitchChange} />} label={t('iscandidate')} />


                        </Grid>
                        <Grid item sm={6}></Grid>
                        <Grid item sm={6}>
                            <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={refresh}>
                                <RefreshIcon style={{ marginRight: 10 }} />{t('clear')}
                            </Button>
                            <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                                <Save style={{ marginRight: 10 }} />{t('save')}
                            </Button>
                        </Grid>
                       
                        <Grid item sm={2}></Grid>
                        <Grid item sm={12}><Divider sx={{ marginBottom: 3, marginTop: 2, backgroundColor: purple[900] }}></Divider></Grid>
                    </Grid>
                </form>
            </Box>
           
            <Box
                sx={{
                    mb: 2,
                    height: 500,
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}
            >

            {/*  table start  */}
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name </TableCell>
                            <TableCell align="left">Address</TableCell>
                            <TableCell align="center">NIC</TableCell>
                            <TableCell align="left">Political Affiliation</TableCell>
                            <TableCell align="left">Is Candidate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {victim && victim.map((row) => {
                            return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.victim_name}
                                </TableCell>
                                <TableCell align="left">{row.victim_address}</TableCell>
                                <TableCell align="center">{row.victim_nic}</TableCell>
                                <TableCell align="center">{row.political_affiliation}</TableCell>
                                <TableCell align="center">{row.victim_is_candidate ? 'Yes' : 'No'}</TableCell>
                            </TableRow>

                        })}
                    </TableBody>
                </Table>
                </Box>
        </Box>
    )
}

export default Victimform