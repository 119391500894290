import React from "react";
import { Container, Grid } from "@mui/material";
import DistrictCountChart from "./containers/DistrictCountChart";
import {
  NotificationContainer,
} from "react-notifications";


export const DashboardChart = () => {

  return (
    <Container
      component="main"
      style={{ background: "#efefef", minHeight: "55vh", marginBottom: -2 }}
    >
      <NotificationContainer />
      <Grid container>
        <Grid item sm={12}>
          <DistrictCountChart />
        </Grid>
      </Grid>
    </Container>
  );
};