import * as React from 'react';
import { Box, Button, Modal, TextField } from '@mui/material';
import FabButton from './FabButton';
import { t } from 'i18next';
import Duplicateform from './Duplicateform';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #4A148C',
  borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

export default function Duplicatemodel(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  return (
    <div>
      <FabButton color='#4A148C' onClick={handleOpen} title={t('duplicate')}></FabButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Duplicateform close={handleClose} id={props.id} formclose={props.close}/>
        </Box>
      </Modal>
    </div>
  );
}