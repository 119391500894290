import React from "react";
import {
  Stack,
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

function AdminDisplayBox4(props) {
  return (
    <Card sx={{ 
      height: "200px",
      width: "100%",
      marginLeft: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      }}>
      <CardContent>
        <Grid  spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid container alignItems="center">
          <Typography 
          color="primary" 
          // variant="h5"
          fontSize="1.6rem"
           marginRight= "20px"
          >
                 All   Requests
              </Typography>
            <Avatar
              sx={{
                // backgroundColor: "#f09c48",
                backgroundColor: "#FFD740",
                height: 50,
                width: 50,
              
              }}
            >
              <EmojiPeopleIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Stack>
              
              <Typography>Today/All</Typography>
              <Typography color="textPrimary" variant="h3">
                {props.day}/{props.all}
              </Typography>
            </Stack>
          </Grid>
         
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AdminDisplayBox4;