import React, { useState, useContext, useEffect } from 'react';
import { Stack, Fab, Grid, Typography, Paper, Box, List, ListItemText, Divider } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Store } from '../../utils/Store';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import { apidata, district_data, severity_data } from '../../data/data';
import { styled } from '@mui/material/styles';
import { purple, indigo } from '@mui/material/colors';
import { resmode_data } from '../../data/data';
import { format } from 'date-fns';

function Complaindetails(props) {
    const { state } = useContext(Store);
    const { id, token, user_district, location, user_roles } = state.userInfo;
    const [currentrole, setCurrentrole] = useState(user_roles[0]);
    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [complain, setComplain] = useState({});
    const [admindiv, setAdmindiv] = useState({});
    const [policediv, setPolicediv] = useState({});
    const [policestation, setPolicestation] = useState({});
    const [categoryset, setCategoryset] = useState(state.categories);
    const [districtset, setDistrictset] = useState(district_data);
    const [sevierityset, setSevierityset] = useState(severity_data);
    const [val, setVal] = useState(0);



    const SubTitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: '#000000',
        paddingLeft: 4,
        marginTop: 1
    }));

    const BodyTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "14px",
        color: '#5a5a5a',
        paddingLeft: 4,
        marginBottom: 10,
        marginTop: 1
    }));

    useEffect(() => {
        //console.log(props.child.id);
        loadData(props.child.id);
        return () => {
        }
    }, []);

    const changePoliceStation = () => {

    };

    const loadData = async (id) => {
        setLoading(true);
        console.log(id);
        await axios.get(apidata.api + 'complain/one', { params: { id: id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                if (response.status == 200) {
                    console.log(response.data.data);
                    setComplain(response.data.data.details);
                    setAdmindiv(response.data.data.admindiv);
                    setPolicediv(response.data.data.policediv);
                    setPolicestation(response.data.data.policestation);
                    setLoaded(true);
                    setLoading(false);
                }
            })
            .catch(err => {
                setLoading(false);
            });
    }

    return (
        !loading && <>
            <NotificationContainer />
            <Box sx={{ minWidth: 700 }}>
                <Grid container >
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy sx={{ marginTop: 2 }}>
                            {t('edr_complain_title')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy >
                            {props.child.title}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy >
                            {t('edr_complain_desc')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                        {props.child.description}
                            {/* {complain.description_en} */}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy>
                            {t('edr_complain_category')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {categoryset.filter(category => category.id == complain.category).map(filteredcategory => (
                                filteredcategory.value_en
                            ))}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy>
                            {t('edr_complain_severity')}
    
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {sevierityset.filter(sev => sev.id == complain.severity).map(filteredsev => (
                                filteredsev.value_en
                            ))}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy >
                            {t('edr_complain_date')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {'Date: ' + complain.item_date + ' Time: ' + complain.item_time}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy>
                            {t('edr_resmode')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {resmode_data.filter(resmode => resmode.id == complain.res_mode).map(resmode => (
                                resmode.value_en
                            ))}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy >
                            {t('edr_received_date')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {'Date: '+format(new Date(props.child.createdAt), 'MM/dd/yyyy HH:mm:ss')}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={10}>
                        <SubTitleTypograpy>
                            {t('edr_complainer')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {'Primary Complainer: ' + complain.complainer_name_en + ' Contact No: ' + complain.complainer_tp}
                        </BodyTypograpy>
                        <BodyTypograpy>
                            {'Secondary Complainer: ' + complain.complainer_name_sec_en + ' Contact No: ' + complain.complainer_tp1}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <SubTitleTypograpy >
                            {t('edr_complain_location')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <BodyTypograpy>
                            {complain.location_en}
                        </BodyTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={10}>
                        <SubTitleTypograpy>
                            {t('edr_other_details_of_location')}
                        </SubTitleTypograpy>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <List>
                            <ListItemText><BodyTypograpy>{'District: '}
                                {districtset.filter(district => district.id == complain.district).map(filtereddistrict => (
                                    filtereddistrict.value_en
                                ))} </BodyTypograpy></ListItemText>
                            <ListItemText><BodyTypograpy>{t('admin_division')} {admindiv != null ? admindiv.name_en : ''} </BodyTypograpy></ListItemText>
                            <ListItemText><BodyTypograpy>{t('police_division')} {policediv ? policediv.name_en : ''} </BodyTypograpy></ListItemText>
                            <ListItemText><BodyTypograpy> {t('police_station')} {policestation ? policestation.name_en : ''} </BodyTypograpy></ListItemText>
                        </List>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    </Grid>
                    
                </Grid>
            </Box>
        </>
    )
}

export default Complaindetails