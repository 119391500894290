import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box, Button, Grid, Select, InputLabel, Typography, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save, Close } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';

function Verifyform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [category, setCategory] = useState('');
    const [categoryset, setCategoryset] = useState(state.categories);
    const [severity, setSeverity] = useState('');
    const [severityset, setSeverityset] = useState(severity_data);
    const [child, setChild] = useState({
        complain_from: '',
        complain_to: '',
        ec_ref:''
    });

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
          ...prevState,
          [name]: value
        }));
      };

    const handleCategoryChange = (event) => {
        const { name, value } = event.target;
        setCategory(value);
    }

    const handleSeverityChange = (event) => {
        const { name, value } = event.target;
        setSeverity(value);
    }

    const kidSchema = yup.object({
        category: yup
            .object()
            .required(t('category_is_required')),
        severity: yup
            .object()
            .required(t('severity_is_required')),
        complain_from: yup
            .string()
            .required(t('complain_from_required')),
        complain_to: yup
            .string()
            .required(t('complain_to_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {
        axios.put(apidata.api + 'edritem/verify', {
            itemId: props.id,
            status: 'VERIFIED',
            category: category.id,
            severity: severity.id,
            ec_ref:data.ec_ref,
            complain_from:data.complain_from,
            complain_to:data.complain_to,
            userId: id
        }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('Success, complain verified'), '');
                props.close();
                props.formclose();
            })
            .catch(error => {
                NotificationManager.error(error.message, '');
            })
    }

    return (
        <Box>
            <NotificationContainer />
            <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>{t('complain_verify')}</TitleTypograpy>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">{t('category')}</InputLabel>
                            <Select
                                sx={{
                                    minWidth: 600, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 3, "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                defaultValue={category.value_en}
                                name='category'
                                fullWidth
                                id='category'
                                {...register('category', { required: true })}
                                error={errors.category}
                                helperText={errors.category && errors.category.message}
                                value={category.value_en}
                                onChange={handleCategoryChange}
                            >
                                {categoryset.map(x => {
                                    return <MenuItem value={x} style={{ fontSize: 12 }}>{x.id + ". "+x.value_en}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">{t('severity_level')}</InputLabel>
                            <Select
                                sx={{
                                    minWidth: 300, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 3, "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                defaultValue={severity.value_en}
                                name='severity'
                                fullWidth
                                id='severity'
                                {...register('severity', { required: true })}
                                error={errors.severity}
                                helperText={errors.severity && errors.severity.message}
                                value={severity.value_en}
                                onChange={handleSeverityChange}
                            >
                                {severityset.map(x => {
                                    return <MenuItem value={x} style={{ fontSize: 12 }}>{x.value_en}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={5}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginRight:1, marginLeft: 3, marginTop: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('ec_ref')}
                            id='ec_ref'
                            name='ec_ref'
                            {...register('ec_ref', { required: true })}
                            value={child.ec_ref}
                            onChange={handleInputChange}
                            error={errors.ec_ref}
                            helperText={errors.ec_ref && errors.ec_ref.message}
                        />
                    </Grid >
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginRight:1, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('complain_from')}
                            id='complain_from'
                            name='complain_from'
                            {...register('complain_from', { required: true })}
                            value={child.complain_from}
                            onChange={handleInputChange}
                            error={errors.complain_from}
                            helperText={errors.complain_from && errors.complain_from.message}
                        />
                    </Grid >
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 2, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('complain_to')}
                            id='complain_to'
                            name='complain_to'
                            {...register('complain_to', { required: true })}
                            value={child.complain_to}
                            onChange={handleInputChange}
                            error={errors.complain_to}
                            helperText={errors.complain_to && errors.complain_to.message}
                        />
                    </Grid >
                    <Grid item sm={6}></Grid>
                    <Grid item sm={6}>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('verify')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default Verifyform