import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Switch,
  FormControlLabel,
  inputBaseClasses,
  InputLabel,
  Typography,
  Paper,
  Button,
  MenuItem,
  Box,
  Container,
  FormControl,
  Grid,
  TextField,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Complainwizard from "./Complainwizard";
import { Save, Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  apidata,
  resmode_data,
  category_data,
  district_data,
} from "../../data/data";
import { Store } from "../../utils/Store";
import Imageuploader from "./Imageuploader";
import { styled } from "@mui/material/styles";
import { purple } from "@mui/material/colors";

// spinner test
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";

function ComplainForm() {
  const date = new Date();
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const { id, token, user_district, user_roles, location } = state.userInfo;
  const [formstate, setFormstate] = useState(true);
  const [uploadform, setUploadform] = useState(false);
  const [item, setItem] = useState();
  const [formatedDate, setFormatedDate] = useState(
    `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
  );
  const [child, setChild] = useState({
    id: "",
    complain_title: "",
    complain_desc: "",
    complain_location: "",
    complain_date: "",
    complain_time: "",
    complainer_name: "",
    complainer_mobile: "",
    complainer_name1: "",
    complainer_mobile1: "",
  });
  const [resmode, setResmode] = useState("");
  const [resmodeset, setResmodeset] = useState(resmode_data);
  const [districtset, setDistrictset] = useState(district_data);
  const [district, setDistrict] = useState({});
  const [admindiv, setAdmindiv] = useState("");
  const [admindivset, setAdmindivset] = useState([]);
  const [policestation, setPolicestation] = useState("");
  const [policestationset, setPolicestationset] = useState([]);
  const [policediv, setPolicediv] = useState("");
  const [policedivset, setPolicedivset] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryset, setCategoryset] = useState(state.categories);
  const [policedirect, setPolicedirect] = useState(false);

  // spinner test
  const number = 0;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef < number > 0;
  // spinner test
  const buttonSx = {
    ...(success && {
      bgcolor: purple[500],
      "&:hover": {
        bgcolor: purple[700],
      },
    }),
  };

  const TitleTypograpy = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: purple[900],
    fontVariant: "bold",
    marginTop: 10,
    marginBottom: 10,
  }));

  const TextAreaField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#fff",
    [`&.${inputBaseClasses.multiline}`]: {
      height: "auto",
      border: "1px solid red",
    },
  }));

  useEffect(() => {}, []);

  const getFileUploadForm = (data) => {
    setItem(data);
    setUploadform(true);
    setFormstate(false);
    // setLoading(false);
    // setSuccess(true);
  };

  const closeComplainFileUpload = () => {
    setItem(null);
    setUploadform(false);
    setFormstate(true);
    // setLoading(false);
    // setSuccess(true);
  };

  const refresh = (event) => {
    setChild({
      id: "",
      complain_title: "",
      complain_desc: "",
      complain_location: "",
      complain_date: "",
      complain_time: "",
      complainer_name: "",
      complainer_mobile: "",
    });
    setDistrict("");
    setResmode("");
    setAdmindiv("");
    setAdmindiv("");
    setPolicestation("");
    setPolicedirect(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResmodeChange = (event) => {
    const { name, value } = event.target;
    setResmode(value);
  };

  const handleDistrictChange = (event) => {
    const { name, value } = event.target;
    setDistrict(value);
    setAdmindiv([]);
    getAdminDivs(value.id);
    getPoliceDivs(value.id);
    //getPolicestations();
  };

  const handleAdminDivChange = (event) => {
    const { name, value } = event.target;
    setAdmindiv(value);
    //getPolicestations(value.id);
  };

  const handlePoliceDivChange = (event) => {
    const { name, value } = event.target;
    setPolicediv(value);
    getPolicestations(value.id);
  };

  const handlePolicestationChange = (event) => {
    const { name, value } = event.target;
    setPolicestation(value);
  };

  const getAdminDivs = (district) => {
    axios
      .get(apidata.api + "support/admindivsviadistrict", {
        params: { id: district },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        //console.log(data.data.data)
        setAdmindivset(data.data.data);
      })
      .catch((error) => {
        setAdmindivset([]);
        NotificationManager.error(t("admin_div_load_error"), "");
      });
  };

  const getPoliceDivs = (district) => {
    axios
      .get(apidata.api + "support/policedivsviadistrict", {
        params: { id: district },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        //console.log(data.data.data)
        setPolicedivset(data.data.data);
      })
      .catch((error) => {
        setPolicedivset([]);
        NotificationManager.error(t("policestation_load_error"), "");
      });
  };

  const getPolicestations = (policediv) => {
    axios
      .get(apidata.api + "support/policesviapolicediv", {
        params: { id: policediv },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        //console.log(data.data.data)
        setPolicestationset(data.data.data);
      })
      .catch((error) => {
        setPolicestationset([]);
        NotificationManager.error(t("policestation_load_error"), "");
      });
  };

  const handleCategoryChange = (event) => {
    const { name, value } = event.target;
    setCategory(value);
  };

  const handleSwitchChange = (event) => {
    const { name, value } = event.target;
    setPolicedirect(!policedirect);
  };

  const formSchema = yup.object({
    complain_title: yup.string("").required(t("title_required")),
    complain_desc: yup.string("").required(t("description_required")),
    complain_location: yup.string("").required(t("location_required")),
    complain_date: yup.date().required(t("date_required")),
    complain_time: yup.string("").required(t("time_required")),
    resmode: yup.object().required(t("received_mode_required")),
    category: yup.object("").required(t("category_required")),
    district: yup.object().required(t("district_required")),
    admindiv: yup.object().required(t("police_required")),
    policediv: yup.object().required(t("police_required")),
    policestation: yup.object().required(t("police_station_required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data) => {
    // spinner test
    setSuccess(false);
    setLoading(true);

    const lng = localStorage.getItem("i18nextLng");

    axios
      .post(
        apidata.api + "edritem",
        {
          title: data.complain_title,
          description: data.complain_desc,
          item_type: "COMPLAIN",
          lang: lng ? lng : "en",
          init_res_center: location,
          act_res_center: location,
          center: user_district,
          pd_res_center: user_roles[0].id == 12 ? location : 0,
          user_id: id,
          location_en: data.complain_location,
          location_si: data.complain_location,
          location_ta: data.complain_location,
          item_date: data.complain_date,
          item_time: data.complain_time,
          res_mode: resmode.id,
          category: category.id,
          district: district.id,
          admin_div: admindiv.id,
          police_div: policediv.id,
          police_station: policestation.id,
          complainer_name_en: data.complainer_name,
          complainer_name_sec_en: data.complainer_name1,
          //need to change
          complainer_tp: data.complainer_mobile,
          complainer_tp1: data.complainer_mobile1,
          policedirect: policedirect,
          user_role: user_roles[0].id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        NotificationManager.success(t("data_saved"), "");
        const c = {
          complain_id: data.data.data.id,
          complain_title: data.data.data.title,
        };
        refresh();
        getFileUploadForm(c);
        // spinner test
        timer.current = window.setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        NotificationManager.error(t(error.response.data.code), "");
        // spinner test
        timer.current = window.setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 2000);
      });
  };

  return (
    <Container component="main" minWidth="xs">
      <NotificationContainer />
      {formstate && (
        <Paper elevation={5} style={{ padding: 30 }}>
          <Complainwizard activeStep={0} />
          <form id="childform" onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item sm={12} xs={10}>
                <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>
                  {t("edr_complain_form_title")}
                </TitleTypograpy>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  label={t("edr_complain_title")}
                  id="complain_title"
                  name="complain_title"
                  {...register("complain_title", { required: true })}
                  value={child.complain_title}
                  onChange={handleInputChange}
                  error={errors.complain_title}
                  helperText={
                    errors.complain_title && errors.complain_title.message
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  id="complain_desc"
                  name="complain_desc"
                  multiline
                  minRows={5}
                  maxRows={5}
                  fullWidth
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 1,
                    backgroundColor: "#fff",
                  }}
                  label={t("edr_complain_desc")}
                  {...register("complain_desc", { required: true })}
                  value={child.complain_desc}
                  onChange={handleInputChange}
                  error={errors.complain_desc}
                  helperText={
                    errors.complain_desc && errors.complain_desc.message
                  }
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <TextField
                  fullWidth
                  sx={{
                    marginLeft: 1,
                    marginRight: 10,
                    marginTop: 2,
                    marginBottom: 0,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  label={t("edr_complain_location")}
                  id="complain_location"
                  name="complain_location"
                  {...register("complain_location", { required: true })}
                  value={child.complain_location}
                  onChange={handleInputChange}
                  error={errors.complain_location}
                  helperText={
                    errors.complain_location && errors.complain_location.message
                  }
                />
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}
              >
                <TextField
                  type="date"
                  sx={{
                    marginLeft: 1,
                    marginRight: 10,
                    marginTop: 2,
                    marginBottom: 0,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_complain_date")}
                  id="complain_date"
                  name="complain_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("complain_date", { required: true })}
                  defaultValue={formatedDate}
                  value={child.complain_date}
                  onChange={handleInputChange}
                  error={errors.complain_date}
                  helperText={
                    errors.complain_date && errors.complain_date.message
                  }
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  type="time"
                  sx={{
                    marginLeft: 1,
                    marginRight: 10,
                    marginTop: 2,
                    marginBottom: 0,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  ampm={false}
                  fullWidth
                  label={t("edr_complain_time")}
                  id="complain_time"
                  name="complain_time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("complain_time", { required: true })}
                  value={child.complain_time}
                  onChange={handleInputChange}
                  error={errors.complain_time}
                  helperText={
                    errors.complain_time && errors.complain_time.message
                  }
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_resmode")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 150,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={resmode.value_en}
                    name="resmode"
                    fullWidth
                    id="resmode"
                    {...register("resmode", { required: true })}
                    error={errors.resmode}
                    helperText={errors.resmode && errors.resmode.message}
                    value={resmode.value_en}
                    onChange={handleResmodeChange}
                  >
                    {resmodeset.map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.value_en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={9} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("category")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 800,
                      maxHeight: 40,
                      marginRight: 10,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={category.value_en}
                    name="category"
                    fullWidth
                    id="category"
                    {...register("category", { required: true })}
                    error={errors.category}
                    helperText={errors.category && errors.category.message}
                    value={category.value_en}
                    onChange={handleCategoryChange}
                  >
                    {categoryset.map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.id + ". "+x.value_en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={10}>
                <TitleTypograpy style={{ marginTop: 10 }}>
                  {t("edr_more_location")}
                </TitleTypograpy>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_district")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 200,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={
                      districtset.filter((x) => x.id == user_district)[0]
                        .value_en
                    }
                    name="district"
                    fullWidth
                    id="district"
                    {...register("district", { required: true })}
                    error={errors.district}
                    helperText={errors.district && errors.district.message}
                    value={district.value_en}
                    onChange={handleDistrictChange}
                  >
                    {districtset.map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.value_en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_admin_div")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 200,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={admindiv.name_en}
                    name="admindiv"
                    fullWidth
                    id="admindiv"
                    {...register("admindiv", { required: true })}
                    error={errors.admindiv}
                    helperText={errors.admindiv && errors.admindiv.message}
                    value={admindiv.name_en}
                    onChange={handleAdminDivChange}
                  >
                    {admindivset.map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.name_en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_police_div")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 200,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={policediv.name_en}
                    name="policediv"
                    fullWidth
                    id="policediv"
                    {...register("policediv", { required: true })}
                    error={errors.policediv}
                    helperText={errors.policediv && errors.policediv.message}
                    value={policediv.name_en}
                    onChange={handlePoliceDivChange}
                  >
                    {policedivset &&
                      policedivset.map((x) => {
                        return (
                          <MenuItem value={x} style={{ fontSize: 12 }}>
                            {x.name_en}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_close_police_station")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 200,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={policestation.name_en}
                    name="policestation"
                    fullWidth
                    id="policestation"
                    {...register("policestation", { required: true })}
                    error={errors.policestation}
                    helperText={
                      errors.policestation && errors.policestation.message
                    }
                    value={policestation.name_en}
                    onChange={handlePolicestationChange}
                  >
                    {policestationset &&
                      policestationset.map((x) => {
                        return (
                          <MenuItem value={x} style={{ fontSize: 12 }}>
                            {x.name_en}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} xs={10}>
                <TitleTypograpy style={{ marginTop: 10 }}>
                  {t("edr_complainer")}
                </TitleTypograpy>
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_complainer_name_primary")}
                  id="complainer_name"
                  name="complainer_name"
                  {...register("complainer_name", { required: true })}
                  value={child.complainer_name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_approval_req__mobile")}
                  id="complainer_mobile"
                  name="complainer_mobile"
                  {...register("complainer_mobile", { required: true })}
                  value={child.complainer_mobile}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={2} xs={12} style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={
                    <Switch
                      id="policedirect"
                      name="policedirect"
                      checked={policedirect}
                      onChange={handleSwitchChange}
                    />
                  }
                  label={t("police_direct")}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_complainer_name_secondary")}
                  id="complainer_name1"
                  name="complainer_name1"
                  {...register("complainer_name1", { required: true })}
                  value={child.complainer_name1}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                sm={3}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_approval_req__mobile")}
                  id="complainer_mobile1"
                  name="complainer_mobile1"
                  {...register("complainer_mobile1", { required: true })}
                  value={child.complainer_mobile1}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={12} xs={10}></Grid>
              <Grid item sm={10} xs={6}></Grid>
              <Grid item sm={2} xs={6}>
                <Button
                  style={{ margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  // spinner test
                  sx={buttonSx}
                  disabled={loading}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}

                  {loading && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: purple[900],
                        position: "absoulute",
                        top: "50%",
                        left: "50%",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {uploadform && (
        <Paper elevation={5} style={{ padding: 20 }}>
          <Complainwizard activeStep={1} />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Imageuploader item={item} />
            <h3>{"Reference: EDRPE2024" + item.complain_id}</h3>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={closeComplainFileUpload}
            >
              <Close style={{ marginRight: 10 }} />
              {t("close")}
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default ComplainForm;
