import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Paper,
  FabButton,
  Modal,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import Imageuploader from "../forms/Imageuploader";
import Basicmodel from "../models/Basicmodel";
import { Store } from "../../utils/Store";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import { apidata } from "../../data/data";
import Complaindetails from "./Complaindetails";
import Attachmentlist from "../lists/Attachmentlist";
import Requestdetails from "./Requestdetails";
import Attachmentmodel from "../models/Attachmentmodel";
import Commentmodel from "../models/Commentmodel";
import Verifymodel from "../models/Verifymodel";
import Assignmodel from "../models/Assignmodel";
import Closemodel from "../models/Closemodel";
import Similermodel from "../models/Similermodel";
import Policeassignmodel from "../models/Policeassignmodel";
import Duplicatemodel from "../models/Duplicatemodel";
import { styled } from "@mui/material/styles";
import { purple, indigo } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";
import Suspectsmodel from "../models/Suspectsmodel";
import Progressmodel from "../models/Progressmodel";
import Courtform from "../models/Courtform";
import Courtmodel from "../models/Courtmodel";
import DamagedPropertymodel from "../models/DamaegedPropertymodel";
import PropertyCustodymodel from "../models/PropertyCustodymodel";
import Victimmodel from "../models/Victimmodel";
import CancelIcon from "@mui/icons-material/Cancel";
import Editmodel from "../models/Editmodel";

const SubTitleTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: purple[900],
}));

const BodyTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: indigo[300],
  paddingLeft: 4,
  marginBottom: 10,
}));

const openModel = (c) => {
  switch (c) {
    case "ATTACHMENT":
      window.alert("Hi");
      break;
    default:
  }
};

function Itemdetails(props) {
  const { state } = useContext(Store);
  const { t, i18n } = useTranslation();
  const currLang = i18n.language;
  const { id, username, token, location, user_district } = state.userInfo;
  const current_role = state.userInfo.user_roles[0];

  useEffect(() => {
    if (current_role && current_role.id == 4) {
      if (props.item.item_level == "NEW") {
        changeStatusOfItem(props.item.id, "OPEN", id);
      }
    }
    if (current_role && current_role.id == 9) {
      if (props.item.item_level == "POLICE_ASSIGN") {
        changeStatusOfItem(props.item.id, "POLICE_OPEN", id);
      }
    }

    if (current_role && current_role.id == 12) {
      //window.alert('level 1');
      if (props.item.sub_item_level == "POLICE_DIV_NEW") {
        //window.alert('level 2');
        changeStatusOfItemPolDiv(props.item.id, "POLICE_DIV_OPEN", id);
      }
    }

    if (current_role && current_role.id == 11) {
      if (props.item.item_level == "COURT_ASSIGN") {
        changeStatusOfItem(props.item.id, "COURT_OPEN", id);
      }
    }
    return () => {};
  }, []);

  const changeStatusOfItemPolDiv = (itemId, status, id) => {
    //window.alert('level 3');
    axios
      .put(
        apidata.api + "edritem/statuspoldiv",
        {
          itemId: itemId,
          status: status,
          user_id: id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        //NotificationManager.success(t('status changed'), '');
      })
      .catch((error) => {
        //NotificationManager.error(t('error_data_save - ' + error), '');
        console.log(error);
      });
  };

  const changeStatusOfItem = (itemId, status, id) => {
    axios
      .put(
        apidata.api + "edritem/status",
        {
          itemId: itemId,
          status: status,
          user_id: id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        //NotificationManager.success(t('status changed'), '');
      })
      .catch((error) => {
        //NotificationManager.error(t('error_data_save - ' + error), '');
        console.log(error);
      });
  };

  return (
    <>
      <Grid container>
        {current_role && current_role.id == 2 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Duplicatemodel id={props.item.id} close={props.changeState} />
            <Commentmodel id={props.item.id} />
            <Attachmentmodel id={props.item.id} item={props.item} />
          </Box>
        ) : (
          <Grid item sm={11} style={{ margin: 5 }}></Grid>
        )}

        {current_role &&
        current_role.id == 4 &&
        props.item.item_level != "CLOSE" &&
        props.item.item_level != "POLICE_ASSIGN" &&
        props.item.item_level != "POLICE_OPEN" &&
        props.item.item_level != "COURT_ASSIGN" &&
        props.item.item_level != "COURT_OPEN" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Similermodel item={props.item} />
            <Duplicatemodel id={props.item.id} close={props.changeState} />
            {props.item.status != "VERIFIED" ? (
              <Verifymodel id={props.item.id} close={props.changeState} />
            ) : (
              ""
            )}
            <Commentmodel id={props.item.id} />
            <Attachmentmodel id={props.item.id} item={props.item} />
            <Assignmodel
              id={props.item.id}
              item={props.item}
              close={props.changeState}
            />
            <Closemodel id={props.item.id} close={props.changeState} />
          </Box>
        ) : (
          <Grid item sm={11} style={{ margin: 5 }}></Grid>
        )}
        {current_role &&
        current_role.id == 9 &&
        props.item.sub_item_level != "POLICE_DIV_NEW" &&
        props.item.sub_item_level != "POLICE_DIV_OPEN" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Commentmodel id={props.item.id} />
            <Attachmentmodel id={props.item.id} item={props.item} />
            <Policeassignmodel
              id={props.item.id}
              item={props.item}
              close={props.changeState}
            />
            <Closemodel id={props.item.id} close={props.changeState} />
          </Box>
        ) : (
          <Grid item sm={11} style={{ margin: 5 }}></Grid>
        )}
        {current_role && current_role.id == 12 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Stack>
              <Stack direction="col">
                <Similermodel item={props.item} />
                <Duplicatemodel id={props.item.id} close={props.changeState} />
                {props.item.status != "VERIFIED" ? (
                  <Verifymodel id={props.item.id} close={props.changeState} />
                ) : (
                  ""
                )}
                <Commentmodel id={props.item.id} />
                <Attachmentmodel id={props.item.id} item={props.item} />
              </Stack>
              <Stack direction="col" sx={{ marginTop: 1 }}>
                <Victimmodel id={props.item.id} item={props.item} />
                <Suspectsmodel id={props.item.id} item={props.item} />
                <DamagedPropertymodel id={props.item.id} item={props.item} />
                <PropertyCustodymodel id={props.item.id} item={props.item} />
                <Progressmodel id={props.item.id} item={props.item} />
                <Courtmodel id={props.item.id} item={props.item} />
                <Closemodel
                  id={props.item.id}
                  close={props.changeState}
                  closeall={props.changeState}
                />
              </Stack>
            </Stack>
          </Box>
        ) : (
          <Grid item sm={11} style={{ margin: 5 }}></Grid>
        )}

        {current_role && current_role.id == 11 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Commentmodel id={props.item.id} />
            <Attachmentmodel id={props.item.id} item={props.item} />
            <Closemodel id={props.item.id} close={props.changeState} />
          </Box>
        ) : (
          <Grid item sm={11} style={{ margin: 5 }}></Grid>
        )}
        <Paper elevation={5}>
          <Grid container style={{ padding: "20px", background: "#fff" }}>
            <Grid item sm={9}>
              <Stack direction="row" spacing={2}>
                <SubTitleTypograpy
                  variant="h6"
                  sx={{
                    color:
                      props.item.status == "VERIFIED" ? "primary" : "#ba000d",
                  }}
                >
                  {t("edr_complain_form_title") +
                    " (Reference: EDRPE2024" +
                    props.item.id +
                    " / " +
                    (props.item.item_ref !== null ? props.item.item_ref : "") +
                    ")"}
                </SubTitleTypograpy>
                {props.item.status == "VERIFIED" ? (
                  <h6 sx={{ color: "#00ff00" }}>
                    <VerifiedIcon sx={{ color: "#00ff00" }} />
                    {t("verified")}
                  </h6>
                ) : (
                  <h6 sx={{ color: "#00ff00" }}>
                    <ErrorIcon sx={{ color: "#ba000d" }} />
                    {t("not_verified")}
                  </h6>
                )}
              </Stack>
            </Grid>
            {/* update - add edit button test */}
            <Grid item sm={2}>
              {props.item && props.item.status == "ACTIVE" && (
                <Editmodel id={props.item.id} item={props.item} />
              )}
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.changeState}
              >
                <Close sx={{ color: "#fff" }} />
                Close
              </Button>
            </Grid>

            {/* <Grid item sm={1}>
                            <Button style={{ margin: 10 }} color="primary" onClick={props.changeState}>
                                <CancelIcon style={{ marginRight: 10 }} />
                            </Button>
                        </Grid> */}

            <Grid item sm={12}>
              {props.item.item_type == "COMPLAIN" ? (
                <Complaindetails
                  child={props.item}
                  item_level={props.item.item_level}
                />
              ) : (
                <Requestdetails
                  child={props.item}
                  item_level={props.item.item_level}
                />
              )}
            </Grid>
            <Grid item sm={12}>
              <SubTitleTypograpy style={{ marginTop: 20 }}>
                {t("other_details")}
              </SubTitleTypograpy>
            </Grid>
            <Grid item sm={12}>
              <Attachmentlist id={props.item.id} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default Itemdetails;
