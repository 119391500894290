import React, { useState, useEffect, useContext } from 'react';
import { Stack, Box, Button, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';

function Policeassignform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token, user_district, user_roles, location } = state.userInfo;
    const [policestation, setPolicestation] = useState('');
    const [policestationset, setPolicestationset] = useState([]);
    const [division, setDivision] = useState('');
    const [divisionset, setDivisionset] = useState([]);
    const [child, setChild] = useState({
        comment: '',
    });

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));


    useEffect(() => {
        loadDivisions();
    }, [])


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const kidSchema = yup.object({
        division: yup
            .object()
            .required(t('police_div_required')),
        policestation: yup
            .object()
            .required(t('police_station_required')),
        comment: yup
            .string('')
            .required(t('comment_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });

    const handleDivisionChange = (event) => {
        const { name, value } = event.target;
        setDivision(value);
        getPolicestations(value.id);
    };


    const handlePolicestationChange = (event) => {
        const { name, value } = event.target;
        setPolicestation(value);
    }

    const loadDivisions = async () => {
        await axios.get(apidata.api + 'policedivs/viadistrict', { params: { id: user_district }, headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                if (response.status == 200) {
                    console.log(response.data);
                    setDivisionset(...divisionset, response.data.data);
                }
            })
            .catch(err => {
                console.log(err)
            });
    };

    const getPolicestations = (policediv) => {
        axios.get(apidata.api + 'support/policesviapolicediv', { params: { id: policediv }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                //console.log(data.data.data)
                setPolicestationset(data.data.data);
            })
            .catch(error => {
                setPolicestationset([]);
                NotificationManager.error(t('policestation_load_error'), '');
            })
    }

    const onSubmit = (data) => {
        console.log(data);
        axios.put(apidata.api + 'edritem/assigndiv',
            { comment: data.comment, itemId: props.item.id, user_id: id, division: division.id, policestation: policestation.id },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('complain_assigned'), '');
                props.close();
                props.formclose();
            })
            .catch(error => {
                NotificationManager.error(t('complain_assigned_not_assigned') + error, '');
            })
    }

    return (

        <form id="childform" onSubmit={handleSubmit(onSubmit)}>
            <Box direction='row'>
                <NotificationContainer />
                <Stack>
                    <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>{t('assign_complain')}</TitleTypograpy>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">{t('divisions')}</InputLabel>
                        <Select
                            sx={{
                                minWidth: 200, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 3, "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            fullWidth
                            labelId="demo-simple-select-label"
                            name='division'
                            id='division'
                            {...register('division', { required: true })}
                            error={errors.division}
                            helperText={errors.division && errors.division.message}
                            value={division.name_en}
                            onChange={handleDivisionChange}
                        >
                            {divisionset.map(x => {
                                return <MenuItem value={x} style={{ fontSize: 12 }}>{x.name_en}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">{t('edr_close_police_station')}</InputLabel>
                        <Select
                            sx={{
                                minWidth: 200, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 3, "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            labelId="demo-simple-select-label"
                            defaultValue={policestation.name_en}
                            name='policestation'
                            fullWidth
                            id='policestation'
                            {...register('policestation', { required: true })}
                            error={errors.policestation}
                            helperText={errors.policestation && errors.policestation.message}
                            value={policestation.name_en}
                            onChange={handlePolicestationChange}
                        >
                            {policestationset && policestationset.map(x => {
                                return <MenuItem value={x} style={{ fontSize: 12 }}>{x.name_en}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{
                            marginLeft: 1, marginBottom: 1, backgroundColor: '#fff',
                        }}
                        multiline
                        minRows={5}
                        maxRows={5}
                        label={t('comment')}
                        id='comment'
                        name='comment'
                        {...register('comment', { required: true })}
                        value={child.comment}
                        onChange={handleInputChange}
                        error={errors.comment}
                        helperText={errors.comment && errors.comment.message}
                    />
                    <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                            <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                                <CancelIcon style={{ marginRight: 10 }} />{t('cancel')}
                            </Button>
                            <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                                <Save style={{ marginRight: 10 }} />{t('save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </form>

    )
}

export default Policeassignform