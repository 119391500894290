import React from "react";
import {
  Stack,
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

function AdminDisplayBox(props) {
  return (
    <Card sx={{ 
      // height: "100%", width: "25%", marginLeft: 1 
      height: "200px",
      width: "100%",
      marginLeft: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
      }}>
      <CardContent>
        <Grid  spacing={2} sx={{ justifyContent: "space-between" }}>
        <Grid container alignItems="center">
          <Typography 
            color="primary" 
            // variant="h5"
            fontSize="1.6rem"
            marginRight= "10px"
            >
                All Complains
          </Typography>
            <Avatar
              sx={{
                // backgroundColor: "#9a37f0",
                backgroundColor: "#FF6F00",
                height: 50,
                width: 50,
              }}
            >
              <MarkEmailReadIcon />
            </Avatar>
          </Grid>

          <Grid item>
            <Stack>
              
              <Typography>Today/All</Typography>
              <Typography color="textPrimary" variant="h3">
                {props.day1}/{props.all1}
              </Typography>
            </Stack>
          </Grid>
         
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AdminDisplayBox;