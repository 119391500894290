import React, { useState } from "react";
import { Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Store } from "../../utils/Store";
import { apidata } from "../../data/data";
import axios from "axios";
import { useEffect } from "react";

function DistrictCountChart() {
  const { state } = useContext(Store);
  const { token, location, user_roles } = state.userInfo;
  const { t } = useTranslation();
  const [districtData, setDistrictData] = useState();

  useEffect(() => {
    fetchComplainsSummary();
  }, []);

  const fetchComplainsSummary = () => {
    axios
      .get(apidata.api + "edritem/districtsummary", {
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setDistrictData(response.data.items);
          console.log("District complain summary", response.data.items);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  

  return (
    <>
      <Typography
      style={{ fontFamily:"Arial", color: '#8236c9', fontSize: '22px', marginTop:'20px' }}
      >{t("District Complain Summary View")}</Typography>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={1200}
          height={400}
          data={districtData}
          margin={{ top: 30, right: 30, bottom: 100 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="DISTRICT" angle={-45} textAnchor="end" dy={5} />
          <YAxis  />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey="AllComplains" fill="#8884d8" />
          {/* <Bar dataKey="ClosedComplains" fill="#80f280" /> */}
            <Bar dataKey="ClosedComplains" fill="#08c698" />
          
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default DistrictCountChart;