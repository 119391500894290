import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';


function Closeform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token, user_roles } = state.userInfo;
    const [child, setChild] = useState({
        desc: '',
    });

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
      }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const kidSchema = yup.object({
        desc: yup
            .string('')
            .required(t('comment_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });

    const onSubmit = (data) => {
        if (user_roles[0] && (user_roles[0].id == 4)) {
            axios.put(apidata.api + 'edritem/close',
                {
                    title: data.desc,
                    itemId: props.id,
                    userId: id
                },
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(data => {
                    NotificationManager.success(t('complain_was_closed'), '');
                    props.close();
                    props.closeall();
                })
                .catch(error => {
                    NotificationManager.error(t('complain_not_closed') + error, '');
                })
        } else if (user_roles[0] && (user_roles[0].id == 9)) {
            axios.put(apidata.api + 'edritem/closepolice',
                {
                    title: data.desc,
                    itemId: props.id,
                    userId: id
                },
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(data => {
                    NotificationManager.success(t('complain_was_closed'), '');
                    props.close();
                    props.closeall();
                })
                .catch(error => {
                    NotificationManager.error(t('complain_not_closed') + error, '');
                })
        } else if (user_roles[0] && (user_roles[0].id == 12)) {
            axios.put(apidata.api + 'edritem/closepolicediv',
                {
                    title: data.desc,
                    itemId: props.id,
                    userId: id
                },
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(data => {
                    NotificationManager.success(t('complain_was_closed'), '');
                    props.close();
                    props.closeall();
                })
                .catch(error => {
                    NotificationManager.error(t('complain_not_closed') + error, '');
                })
        } else if (user_roles[0] && (user_roles[0].id == 11)) {
            axios.put(apidata.api + 'edritem/closecourt',
                {
                    title: data.desc,
                    itemId: props.id,
                    userId: id
                },
                { headers: { "Authorization": `Bearer ${token}` } })
                .then(data => {
                    NotificationManager.success(t('complain_was_closed'), '');
                    props.close();
                    props.closeall();
                })
                .catch(error => {
                    NotificationManager.error(t('complain_not_closed') + error, '');
                })
        } else {
            NotificationManager.error(t('you_do_not_pocess_permission_to_perform_this_action'));
        }
    }



    return (
        <Box>
            <NotificationContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item sm={12}><TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>{t('add_closing_comment')}</TitleTypograpy></Grid>
                    <Grid item sm={12}>
                        <TextField
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 1, backgroundColor: '#fff',
                            }}
                            multiline
                            minRows={5}
                            maxRows={5}
                            fullWidth
                            label={t('comment')}
                            id='desc'
                            name='desc'
                            {...register('desc', { required: true })}
                            value={child.desc}
                            onChange={handleInputChange}
                            error={errors.desc}
                            helperText={errors.desc && errors.desc.message}
                        />
                    </Grid>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('close_complain')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default Closeform