import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box, Button, Grid, Select, InputLabel, Typography, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, typeofproperty_data, vehi_category_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function PropertyCustodyform(props) {
    const { t } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [val, setVal] = useState(0);
    const [propertycustody, setPropertycustody] = useState([]);

    const [typeofproperty, setTypeofproperty] = useState('');
    const [typeofpropertyset, setTypeofpropertyset] = useState(typeofproperty_data);

    const [vehicategory, setVehicategory] = useState('');
    const [vehicategoryset, setVehicategoryset] = useState(vehi_category_data);
    const [child, setChild] = useState({
        amount_property: '',
        numberof_property: '',
        property_value: '',
        vehicle_reg_no: '',
        remarks: '',
    });

    
    useEffect(() => {
        loadData();
    }, [val]);

    const loadData = () => {
        axios.get(apidata.api + 'propertycustody/propertycustody', { params: { id: props.id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log(data.data.data)
                setPropertycustody(data.data.data);
            })
            .catch(error => {
                console.log(error);
                setPropertycustody([]);
            })
    }

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));


    const refresh = (event) => {
        setChild({
            amount_property: '',
            numberof_property: '',
            property_value: '',
            remarks: '',
            vehicle_reg_no: '',

        });
        setTypeofproperty('');
        setVehicategory('');
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    const handleTypeofPropertyChange = (event) => {
        const { name, value } = event.target;
        setTypeofproperty(value);
    }

    const handleVehiCategoryChange = (event) => {
        const { name, value } = event.target;
        setVehicategory(value);
    }

    const kidSchema = yup.object({
        typeofproperty: yup
            .object()
            .required(t('typeofproperty_is_required')),
        // vehicategory: yup
        //     .object()
        //     .required(t('vehicategory_is_required')),
        // amount_property: yup
        //     .string()
        //     .required(t('amount_property_required')),
        // numberof_property: yup
        //     .string()
        //     .required(t('numberof_property_is_required')),
        // vehicle_reg_no: yup
        //     .string()
        //     .required(t('vehicle_reg_no_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {
       
        console.log(' prop custody ', data);
        axios.post(apidata.api + 'propertycustody', {
            itemId: props.id,
            typeofproperty: typeofproperty.id,
            amount_property: data.amount_property,
            numberof_property: data.numberof_property,
            property_value: data.property_value,
            vehicle_reg_no: data.vehicle_reg_no,
            remarks: data.remarks,
            vehicategory: vehicategory.id,
            created_by: id

        }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('Success, Details of propertycustody'), '');
                console.log('@@@@@@@@', data);
                setVal(val + 1);
                // props.close();
                // props.formclose();
                // refresh();
            })
            .catch(error => {
                NotificationManager.error(error.message, '');
            })
    }

    return (
        <Box
        sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            height: 600,
            overflowX: "scroll",
            // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}>
            <NotificationContainer />
            <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('property_custody')}</TitleTypograpy></Grid>
                        <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />
                        </Button></Grid>
                    <Grid item sm={12} xs={12}>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">{t('typeofproperty')}</InputLabel>
                            <Select
                                sx={{
                                    minWidth: 850, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 2, "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                defaultValue={typeofproperty.value_en}
                                name='typeofproperty'
                                fullWidth
                                id='typeofproperty'
                                {...register('typeofproperty', { required: true })}
                                error={errors.typeofproperty}
                                helperText={errors.typeofproperty && errors.typeofproperty.message}
                                value={typeofproperty.value_en}
                                onChange={handleTypeofPropertyChange}
                            >
                                {typeofpropertyset.map(x => {
                                    return <MenuItem value={x} style={{ fontSize: 12 }}>{x.value_en}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10,  marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('amount_property')}
                            id='amount_property'
                            name='amount_property'
                            {...register('amount_property', { required: true })}
                            value={child.amount_property}
                            onChange={handleInputChange}
                            error={errors.amount_property}
                            helperText={errors.amount_property && errors.amount_property.message}
                        />
                    </Grid >
                    <Grid item sm={5} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 5, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('numberof_property')}
                            id='numberof_property'
                            name='numberof_property'
                            {...register('numberof_property', { required: true })}
                            value={child.numberof_property}
                            onChange={handleInputChange}
                            error={errors.numberof_property}
                            helperText={errors.numberof_property && errors.numberof_property.message}
                        />
                    </Grid >

                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('property_value')}
                            id='property_value'
                            name='property_value'
                            {...register('property_value', { required: true })}
                            value={child.property_value}
                            onChange={handleInputChange}
                            error={errors.property_value}
                            helperText={errors.property_value && errors.property_value.message}
                        />
                    </Grid >
                    <Grid item sm={5} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 5, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('vehicle_reg_no')}
                            id='vehicle_reg_no'
                            name='vehicle_reg_no'
                            {...register('vehicle_reg_no', { required: true })}
                            value={child.vehicle_reg_no}
                            onChange={handleInputChange}
                            error={errors.vehicle_reg_no}
                            helperText={errors.vehicle_reg_no && errors.vehicle_reg_no.message}
                        />
                    </Grid >
                    <Grid item sm={11} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('remarks')}
                            id='remarks'
                            name='remarks'
                            {...register('remarks', { required: true })}
                            value={child.remarks}
                            onChange={handleInputChange}
                            error={errors.remarks}
                            helperText={errors.remarks && errors.remarks.message}
                        />
                    </Grid >
                    <Grid item sm={6} xs={12}>
                        <FormControl >
                            <InputLabel id="demo-simple-select-label">{t('vehi_category')}</InputLabel>
                            <Select
                                sx={{
                                    minWidth: 440, maxHeight: 40, marginLeft: 1, marginTop: 1, marginBottom: 2, "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                defaultValue={vehicategory.value_en}
                                name='vehicategory'
                                fullWidth
                                id='vehicategory'
                                {...register('vehicategory', { required: true })}
                                error={errors.vehicategory}
                                helperText={errors.vehicategory && errors.vehicategory.message}
                                value={vehicategory.value_en}
                                onChange={handleVehiCategoryChange}
                            >
                                {vehicategoryset.map(x => {
                                    return <MenuItem value={x} style={{ fontSize: 12 }}>{x.value_en}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {/* <Grid item sm={6}></Grid> */}
                    <Grid item sm={8}></Grid>
                    <Grid item sm={4}>
                        <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={refresh} >
                            <RefreshIcon style={{ marginRight: 10 }} />{t('clear')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('save')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                        <Grid item sm={12}><Divider sx={{ marginBottom: 3, marginTop: 2, backgroundColor: purple[900] }}></Divider></Grid>
                </Grid>
            </form>
            </Box>

            {/* table */}

                <Box
                 sx={{
                    mb: 2,
                    height: 500,
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Type of Property </TableCell>
                            <TableCell align="center">Amount of Property</TableCell>
                            <TableCell align="center">No of Property</TableCell>
                            <TableCell align="center">Value Of Property</TableCell>
                            <TableCell align="center">Vehicle Reg No</TableCell>
                            <TableCell align="right">Vehicle Category</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {propertycustody && propertycustody.map((row) => {
                            return <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    
                                     {typeofpropertyset.filter(property => property.id === row.typeofproperty).map(filteredpro => (
                                        filteredpro.value_en
                                    ))}
                                    
                                </TableCell>
                                <TableCell align="center">{row.amount_property}</TableCell>
                                <TableCell align="center">{row.numberof_property}</TableCell>
                                <TableCell align="center">{row.property_value}</TableCell>
                                <TableCell align="center">{row.vehicle_reg_no}</TableCell>
                                <TableCell align="right">
                                   
                                    {vehicategoryset.filter(vehi => vehi.id === row.vehicategory).map(filteredvehi => (
                                        filteredvehi.value_en
                                    ))}
                                    </TableCell>
                            </TableRow>

                        })}
                    </TableBody>
                </Table>
                </Box>
                {/* </AutoResizer> */}
            {/* </TableContainer> */}
        </Box>
    )
}

export default PropertyCustodyform