import '../locales/i18n';
import { useTranslation } from "react-i18next";
import { FormPage } from '../components/FormPage';
import ComplainForm from '../components/forms/ComplainForm';
import InquaryForm from '../components/forms/InquaryForm';

export const ComplainPage = () => {
  const { t } = useTranslation();
  return <FormPage title={t('home_page')} complain={<ComplainForm />} inquary={<InquaryForm />}/>
};
