import React, { useContext, useEffect, useState } from "react";
import { Grid, Typography, Paper, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { Store } from "../../utils/Store";
import "react-notifications/lib/notifications.css";
import axios from "axios";
import { apidata } from "../../data/data";
import Complaindetails from "./Complaindetails";
import Attachmentlist from "../lists/Attachmentlist";
import Requestdetails from "./Requestdetails";
import { styled } from "@mui/material/styles";
import { purple, indigo } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import ErrorIcon from "@mui/icons-material/Error";

const SubTitleTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: purple[900],
}));

const BodyTypograpy = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: indigo[300],
  paddingLeft: 4,
  marginBottom: 10,
}));

const openModel = (c) => {
  switch (c) {
    case "ATTACHMENT":
      window.alert("Hi");
      break;
    default:
  }
};

function Itemdetailspoliceall(props) {
  const { state } = useContext(Store);
  const { t, i18n } = useTranslation();
  const currLang = i18n.language;
  const { id, username, token, location, user_district } = state.userInfo;
  const current_role = state.userInfo.user_roles[0];

  useEffect(() => {
    if (current_role && current_role.id == 4) {
      if (props.item.item_level == "NEW") {
        changeStatusOfItem(props.item.id, "OPEN", id);
      }
    }
    if (current_role && current_role.id == 9) {
      if (props.item.item_level == "POLICE_ASSIGN") {
        changeStatusOfItem(props.item.id, "POLICE_OPEN", id);
      }
    }

    if (current_role && current_role.id == 12) {
      //window.alert('level 1');
      if (props.item.sub_item_level == "POLICE_DIV_NEW") {
        //window.alert('level 2');
        changeStatusOfItemPolDiv(props.item.id, "POLICE_DIV_OPEN", id);
      }
    }

    if (current_role && current_role.id == 11) {
      if (props.item.item_level == "COURT_ASSIGN") {
        changeStatusOfItem(props.item.id, "COURT_OPEN", id);
      }
    }
    return () => {};
  }, []);

  const changeStatusOfItemPolDiv = (itemId, status, id) => {
    //window.alert('level 3');
    axios
      .put(
        apidata.api + "edritem/statuspoldiv",
        {
          itemId: itemId,
          status: status,
          user_id: id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        //NotificationManager.success(t('status changed'), '');
      })
      .catch((error) => {
        //NotificationManager.error(t('error_data_save - ' + error), '');
        console.log(error);
      });
  };

  const changeStatusOfItem = (itemId, status, id) => {
    axios
      .put(
        apidata.api + "edritem/status",
        {
          itemId: itemId,
          status: status,
          user_id: id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        //NotificationManager.success(t('status changed'), '');
      })
      .catch((error) => {
        //NotificationManager.error(t('error_data_save - ' + error), '');
        console.log(error);
      });
  };

  return (
    <>
      <Grid container>
        <Paper elevation={5} sx={{ marginTop: 5 }}>
          <Grid container style={{ padding: "20px", background: "#fff" }}>
            <Grid item sm={11}>
              <Stack direction="row" spacing={2}>
                <SubTitleTypograpy
                  variant="h6"
                  sx={{
                    color:
                      props.item.status == "VERIFIED" ? "primary" : "#ba000d",
                  }}
                >
                  {t("edr_complain_form_title") +
                    " (Reference: EDRPE2024" +
                    props.item.id +
                    " / " +
                    (props.item.item_ref !== null ? props.item.item_ref : "") +
                    ")"}
                </SubTitleTypograpy>
                {props.item.status == "VERIFIED" ? (
                  <h6 sx={{ color: "#00ff00" }}>
                    <VerifiedIcon sx={{ color: "#00ff00" }} />
                    {t("verified")}
                  </h6>
                ) : (
                  <h6 sx={{ color: "#00ff00" }}>
                    <ErrorIcon sx={{ color: "#ba000d" }} />
                    {t("not_verified")}
                  </h6>
                )}
              </Stack>
            </Grid>
            <Grid item sm={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={props.changeState}
              >
                <Close sx={{ color: "#fff" }} />
                Close
              </Button>
            </Grid>

            <Grid item sm={12}>
              {props.item.item_type == "COMPLAIN" ? (
                <Complaindetails
                  child={props.item}
                  item_level={props.item.item_level}
                />
              ) : (
                <Requestdetails
                  child={props.item}
                  item_level={props.item.item_level}
                />
              )}
            </Grid>
            <Grid item sm={12}>
              <SubTitleTypograpy style={{ marginTop: 20 }}>
                {t("other_details")}
              </SubTitleTypograpy>
            </Grid>
            <Grid item sm={12}>
              <Attachmentlist id={props.item.id} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default Itemdetailspoliceall;
