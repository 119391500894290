import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography, FormControlLabel, Switch, Divider } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, category_data, severity_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function Progressform(props) {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [child, setChild] = useState({
        num_sus_arrested: '',
        inq_officer: '',
        rank: ''
    });
    const [isreleased, setIsreleased] = useState(false);
    const [issetlepolice, setIssetlepolice] = useState(false);
    const [isleagle, setIsleagle] = useState(false);

    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "18px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const kidSchema = yup.object({
        inq_officer: yup
            .string('')
            .required(t('inquary_officer_is_required')),
        rank: yup
            .string('')
            .required(t('rank_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });

    const handleIsreleasedSwitchChange = (event) => {
        const { name, value } = event.target;
        setIsreleased(!isreleased);
    }

    const handleIsleagleSwitchChange = (event) => {
        const { name, value } = event.target;
        setIsleagle(!isleagle);
    }

    const handleIspoliceSwitchChange = (event) => {
        const { name, value } = event.target;
        setIssetlepolice(!issetlepolice);
    }

    const onSubmit = (data) => {
        axios.post(apidata.api + 'policeprogress',
            {
                num_sus_arrested: data.num_sus_arrested,
                inq_officer: data.inq_officer,
                rank: data.rank,
                isreleased: isreleased,
                issetlepolice: issetlepolice,
                isleagle: isleagle,
                itemId: props.id,
                userId: id
            },
            { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                NotificationManager.success(t('progress_saved'), '');
                props.close();
            })
            .catch(error => {
                NotificationManager.error(t('not_saved. ') + error.code, '');
            })
    }


    return (
        <Box>
            <NotificationContainer />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('add_progress')}</TitleTypograpy></Grid>
                    <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                        <CancelIcon style={{ marginRight: 10 }} />
                    </Button></Grid>
                    <Grid item sm={11} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('num_sus_arrested')}
                            id='num_sus_arrested'
                            name='num_sus_arrested'
                            {...register('num_sus_arrested', { required: true })}
                            value={child.sus_name}
                            onChange={handleInputChange}
                            error={errors.num_sus_arrested}
                            helperText={errors.num_sus_arrested && errors.num_sus_arrested.message}
                        />
                    </Grid>
                    <Grid item sm={11} xs={12} sx={{
                        marginRight: 10, marginLeft: 1, backgroundColor: '#fff', "& .MuiInputBase-root": {
                            height: 40,
                            backgroundColor: '#fff'
                        }
                    }}>{t('suspect_interrogated_and_released')}   <FormControlLabel control={<Switch sx={{ marginLeft: 2 }} id='isreleased' name='isreleased' checked={isreleased} onChange={handleIsreleasedSwitchChange} />} label={t('yes')} />
                    </Grid>
                    <Grid item sm={11} xs={12} sx={{
                        marginRight: 10, marginLeft: 1, backgroundColor: '#fff', "& .MuiInputBase-root": {
                            height: 40,
                            backgroundColor: '#fff'
                        }
                    }}>{t('case_settled_by_the_police')}<FormControlLabel control={<Switch sx={{ marginLeft: 2 }} id='issetlepolice' name='issetlepolice' checked={issetlepolice} onChange={handleIspoliceSwitchChange} />} label={t('yes')} />
                    </Grid>
                    <Grid item sm={11} xs={12} sx={{
                        marginRight: 15, marginBottom: 2, marginLeft: 1, backgroundColor: '#fff', "& .MuiInputBase-root": {
                            height: 40,
                            backgroundColor: '#fff'
                        }
                    }}>{t('intending_to_take_legal_action')}<FormControlLabel control={<Switch sx={{ marginLeft: 2 }} id='isleagle' name='isleagle' checked={isleagle} onChange={handleIsleagleSwitchChange} />} label={t('yes')} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('inquary_officer_name')}
                            id='inq_officer'
                            name='inq_officer'
                            {...register('inq_officer', { required: true })}
                            value={child.inq_officer}
                            onChange={handleInputChange}
                            error={errors.inq_officer}
                            helperText={errors.inq_officer && errors.inq_officer.message}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            fullWidth
                            sx={{
                                marginRight: 10, marginLeft: 2, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                    height: 40,
                                    backgroundColor: '#fff'
                                }
                            }}
                            label={t('rank')}
                            id='rank'
                            name='rank'
                            {...register('rank', { required: true })}
                            value={child.rank}
                            onChange={handleInputChange}
                            error={errors.rank}
                            helperText={errors.rank && errors.rank.message}
                        />
                    </Grid>
                    <Grid item sm={6}></Grid>
                    <Grid item sm={6}>
                        <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={props.close}>
                            <RestartAltIcon style={{ marginRight: 10 }} />{t('cancel')}
                        </Button>
                        <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                            <Save style={{ marginRight: 10 }} />{t('save')}
                        </Button>
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default Progressform