import { PoliceDivAllPage } from "../components/PoliceDivAllPage";
import Home from "@mui/icons-material/Home";
import '../locales/i18n';
import { useTranslation } from "react-i18next";


export const AllComplainsPage = () => {
  const { t } = useTranslation();
  
  return <PoliceDivAllPage title={t('all_complains')} icon={<Home />} />;
};