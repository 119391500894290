import React from 'react'
import { Button, Fab } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Close, Edit } from "@mui/icons-material";


function BoxButton(props) {
    const { t } = useTranslation();
    return (
        <>


            <Button style={{ fontSize: 15, color: 'white', width: 110, background: props.color, marginLeft: 25, marginRight: 10 }} color='primary' variant='extended' size='medium' onClick={() => { props.onClick(props.type) }} >
                <Edit sx={{ color: "#fff" }} />
                {t(props.title)}
            </Button>


        </>
    )
}


export default BoxButton
