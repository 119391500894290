import { BasicPage } from "../components/BasicPage";
import Home from "@mui/icons-material/Home";
import "../locales/i18n";
import { useTranslation } from "react-i18next";
import { ReportLayout } from "../components/ReportLayout";
// import  ReportTest  from "../components/ReportTest";

export  const ReportsPage = () => {
  const { t } = useTranslation();
  return <ReportLayout title={t("reports")} icon={<Home />} />;
};
