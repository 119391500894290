import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box, Button, Grid, Select, InputLabel, Typography, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Save } from "@mui/icons-material";
import axios from 'axios';
import { Store } from '../../utils/Store';
import { apidata, sector_data, assest_data } from '../../data/data';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import RefreshIcon from '@mui/icons-material/Refresh';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function DamaegedPropertyform(props) {
    const { t } = useTranslation();
    const { state } = useContext(Store);
    const { id, token } = state.userInfo;
    const [val, setVal] = useState(0);
    const [damagedproperty, setDamagedproperty] = useState([]);

    const [sector, setSector] = useState('');
    const [sectorset, setSectorset] = useState(sector_data);

    const [assest, setAssest] = useState('');
    const [assestset, setAssestset] = useState(assest_data);

    const [child, setChild] = useState({
        property_value: '',
        others: '',

    });

    useEffect(() => {
        loadData();
    }, [val]);

    const loadData = () => {
        axios.get(apidata.api + 'damagedproperty/damagedproperty', { params: { id: props.id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log(data.data.data);
                setDamagedproperty(data.data.data);
            })
            .catch(error => {
                console.log(error);
                setDamagedproperty([]);
            })
    }


    const TitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: purple[900],
        fontVariant: 'bold',
        marginTop: 10,
        marginBottom: 10
    }));

    const refresh = (event) => {
        setChild({
            property_value: '',
            others: '',
        });
        setSector('');
        setAssest('');

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setChild((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSectorChange = (event) => {
        const { name, value } = event.target;
        setSector(value);
    }

    const handleAssestChange = (event) => {
        const { name, value } = event.target;
        setAssest(value);
    }


    const kidSchema = yup.object({
        sector: yup
            .object()
            .required(t('sector_is_required')),
        assest: yup
            .object()
            .required(t('assest_is_required')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(kidSchema)
    });


    const onSubmit = (data) => {

        axios.post(apidata.api + 'damagedproperty', {
            itemId: props.id,
            sector: sector.id,
            assest: assest.id,
            property_value: data.property_value,
            others: data.others,
            created_by: id

        }, { headers: { "Authorization": `Bearer ${token}` } })
            .then(data => {
                console.log('%%%%%%%%%%', data);
                NotificationManager.success(t('Success, Damaged Property'), '');
                setVal(val + 1);
                // props.close();
                // props.formclose();
            })
            .catch(error => {
                NotificationManager.error(error.message, '');
            })
    }

    return (
        <Box
            sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: 600,
                overflowX: "scroll",
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
            }}
        >
            <NotificationContainer />
          
            <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                        <Grid item sm={11}><TitleTypograpy sx={{ marginBottom: 2 }}>{t('damaged_property')}</TitleTypograpy></Grid>
                        <Grid item sm={1}><Button style={{ margin: 10 }} color="primary" onClick={props.close}>
                            <CancelIcon style={{ marginRight: 10 }} />
                        </Button></Grid>

                        <Grid item sm={6} xs={12}>
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">{t('sector_type')}</InputLabel>
                                <Select
                                    sx={{
                                     minWidth:200, maxHeight: 40, marginLeft: 1, marginRight: 10, marginTop: 2, marginBottom: 3, "& .MuiInputBase-root": {
                                            height: 40,
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                    labelId="demo-simple-select-label"
                                    defaultValue={sector.value_en}
                                    name='sector'
                                    fullWidth
                                    id='sector'
                                    {...register('sector', { required: true })}
                                    error={errors.sector}
                                    helperText={errors.sector && errors.sector.message}
                                    value={sector.value_en}
                                    onChange={handleSectorChange}
                                >
                                    {sectorset.map(x => {
                                        return <MenuItem value={x} style={{ fontSize: 12 }}>{x.value_en}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl sx={{ marginLeft: 3 }} >
                                <InputLabel id="demo-simple-select-label">{t('assest_type')}</InputLabel>
                                <Select
                                    sx={{
                                        minWidth:200, maxHeight: 40,marginRight: 10, marginTop: 2, marginBottom: 3, "& .MuiInputBase-root": {
                                            height: 40,
                                            backgroundColor: '#fff'
                                        }
                                    }}
                                    labelId="demo-simple-select-label"
                                    defaultValue={assest.value_en}
                                    name='assest'
                                    fullWidth
                                    id='assest'
                                    {...register('assest', { required: true })}
                                    error={errors.assest}
                                    helperText={errors.assest && errors.assest.message}
                                    value={assest.value_en}
                                    onChange={handleAssestChange}
                                >
                                    {assestset.map(x => {
                                        return <MenuItem value={x} style={{ fontSize: 12 }}>{x.value_en}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    minWidth: 300, marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('property_value')}
                                id='property_value'
                                name='property_value'
                                {...register('property_value', { required: true })}
                                value={child.property_value}
                                onChange={handleInputChange}
                                error={errors.property_value}
                                helperText={errors.property_value && errors.property_value.message}
                            />
                        </Grid >
                          <Grid item sm={6}></Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField
                                fullWidth
                                sx={{
                                    minWidth: 300, marginRight: 10, marginLeft: 1, marginBottom: 2, backgroundColor: '#fff', "& .MuiInputBase-root": {
                                        height: 40,
                                        backgroundColor: '#fff'
                                    }
                                }}
                                label={t('others')}
                                id='others'
                                name='others'
                                {...register('others', { required: true })}
                                value={child.others}
                                onChange={handleInputChange}
                                error={errors.others}
                                helperText={errors.others && errors.others.message}
                            />
                        </Grid >

                        {/* <Grid item sm={6}></Grid> */}
                        <Grid item sm={6}></Grid>
                        <Grid item sm={6}></Grid>
                        <Grid item sm={6}>
                            <Button style={{ margin: 10 }} variant="outlined" color="primary" onClick={refresh}>
                                <RefreshIcon style={{ marginRight: 10 }} />{t('clear')}
                            </Button>
                            <Button style={{ margin: 10 }} variant="contained" color="primary" type="submit">
                                <Save style={{ marginRight: 10 }} />{t('save')}
                            </Button>
                        </Grid>
                        <Grid item sm={2}></Grid>
                        <Grid item sm={12}><Divider sx={{ marginBottom: 3, marginTop: 2, backgroundColor: purple[900] }}></Divider></Grid>
                    </Grid>
                </form>
            </Box>
            
            {/* table */}

            <Box
                sx={{
                    mb: 2,
                    height: 500,
                    overflowY: "scroll",
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}
            >


                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>Sector Type</TableCell>
                            <TableCell align="center">Assest Type</TableCell>
                            <TableCell align="center">Value of Property (RS)</TableCell>
                            <TableCell align="center">Others (RS)</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {damagedproperty && damagedproperty.map((row) => {

                            return <TableRow

                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {sectorset.filter(sec => sec.id === row.sector).map(filteredsec => (
                                        filteredsec.value_en
                                    ))}
                                </TableCell>
                                <TableCell align="center">
                                    {assestset.filter(ass => ass.id === row.assest).map(filteredass => (
                                        filteredass.value_en
                                    ))}</TableCell>
                                <TableCell align="center">{row.property_value}</TableCell>
                                <TableCell align="center">{row.others}</TableCell>

                            </TableRow>

                        })}
                    </TableBody>
                </Table>
            </Box>

        </Box>
    )
}

export default DamaegedPropertyform