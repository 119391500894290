import React, { useState, useContext, useEffect } from 'react';
import { Fab, Grid, Typography, Paper, Stack, Box, Card } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Store } from '../../utils/Store';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import { apidata } from '../../data/data';
import { format } from 'date-fns';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { purple, indigo } from '@mui/material/colors';

function Attachmentlist(props) {
    const { state } = useContext(Store);
    const { token, user_roles } = state.userInfo;
    const [currentrole, setCurrentrole] = useState(user_roles[0]);
    const { t } = useTranslation();
    const [itemlist, setItemlist] = useState([]);
    const [isloading, setIsloading] = useState(false);

    const SubTitleTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "20px",
        color: purple[900],
    }));
    

    const BodyTypograpy = styled(Typography)(({ theme }) => ({
        fontSize: "16px",
        color: indigo[300],
        paddingLeft:4,
        marginBottom:10
    }));

    useEffect(() => {
        fetchComplains(props.id)
        return () => {
        }
    }, []);

    const fetchComplains = (id) => {
        setIsloading(true);
        axios.get(apidata.api + 'edritemlog/allviaitemid', { params: { id: id }, headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                if (response.status == 200) {
                    setItemlist(response.data);
                    setIsloading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setIsloading(false);
            });
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={1} divider={<Divider flexItem />}>
                {itemlist.map((x) => {
                    return <Card style={{ padding: 30, marginBottom: 4 }}>
                        <Grid container>
                            <Grid item sm={12}>
                                <BodyTypograpy>{'  ' + x.comment_org}</BodyTypograpy></Grid>
                            <Grid item sm={12}>
                                {(x.file_path != '') ? <img src={apidata.api + 'resources/' + x.file_path} width='600' height='400' /> : ''}</Grid>
                            <Grid item sm={9}> <Typography variant='body' >{t('entered_by') + x.user.name}</Typography>
                                <Typography variant='body' > ({x.user.position})</Typography>
                                <Typography variant='body' >{t('on') + format(new Date(x.createdAt), 'MM/dd/yyyy HH:mm:ss')}</Typography></Grid>
                            <Grid item sm={3}>
                            </Grid>
                        </Grid>
                    </Card>
                })}
            </Stack>
        </Box>
    )
}

export default Attachmentlist