import React, { useState, useEffect, useRef, useContext } from "react";
import {
  InputLabel,
  Typography,
  Paper,
  Button,
  MenuItem,
  Box,
  Container,
  FormControl,
  Grid,
  TextField,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Complainwizard from "./Complainwizard";
import { Save, Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import {
  apidata,
  resmode_data,
  category_data,
  district_data,
} from "../../data/data";
import { Store } from "../../utils/Store";
import Imageuploader from "./Imageuploader";
import { styled } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";

// spinner test
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";

function InquaryForm() {
  const date = new Date();
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const { id, token, user_district, location } = state.userInfo;
  const [formstate, setFormstate] = useState(true);
  const [uploadform, setUploadform] = useState(false);
  const [item, setItem] = useState();
  const [formatedDate, setFormatedDate] = useState(
    `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`
  );
  const [resmode, setResmode] = useState("");
  const [resmodeset, setResmodeset] = useState(resmode_data);
  const [child, setChild] = useState({
    id: "",
    approval_title: "",
    approval_desc: "",
    resmode: "",
    file_ref: "",
    approval_date: "",
    requester_name: "",
    requester_mobile: "",
    requester_address: "",
    requester_email: "",
  });

  // spinner test
  const number = 0;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef < number > 0;
  // spinner test
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const TitleTypograpy = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: purple[900],
    fontVariant: "bold",
    marginTop: 10,
    marginBottom: 10,
  }));

  const refresh = (event) => {
    setChild({
      id: "",
      approval_title: "",
      approval_desc: "",
      file_ref: "",
      approval_date: "",
      requester_name: "",
      requester_mobile: "",
      requester_address: "",
      requester_email: "",
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setChild((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getFileUploadForm = (data) => {
    setItem(data);
    setUploadform(true);
    setFormstate(false);
    // setLoading(false);
    // setSuccess(true);
  };

  const formSchema = yup.object({
    approval_title: yup.string("").required(t("title_required")),
    approval_desc: yup.string("").required(t("description_required")),
    resmode: yup.object().required(t("received_mode_required")),
    approval_date: yup.date().required(t("date_required")),
    requester_address: yup.string("").required(t("requester_address_required")),
    requester_name: yup.string("").required(t("requester_address_required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const closeComplainFileUpload = () => {
    setItem(null);
    setUploadform(false);
    setFormstate(true);
    // setLoading(false);
    // setSuccess(true);
  };

  const handleResmodeChange = (event) => {
    const { name, value } = event.target;
    setResmode(value);
  };

  const onSubmit = (data) => {
    // spinner test
    setSuccess(false);
    setLoading(true);

    const lng = localStorage.getItem("i18nextLng");
    axios
      .post(
        apidata.api + "edritem",
        {
          title: data.approval_title,
          description: data.approval_desc,
          item_type: "REQUEST",
          lang: lng,
          user_id: id,
          init_res_center: location,
          act_res_center: location,
          district: user_district,
          item_date: data.approval_date,
          resmode: resmode.id,
          file_ref: data.file_ref,
          complainer_name_en: data.requester_name,
          complainer_tp: data.requester_mobile,
          complainer_address_en: data.requester_address,
          complainer_email: data.requester_email,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((data) => {
        NotificationManager.success(t("data_saved"), "");
        const c = {
          complain_id: data.data.data.id,
          complain_title: data.data.data.title,
        };
        refresh();
        getFileUploadForm(c);
        // spinner test
        // timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
        // }, 2000);
      })
      .catch((error) => {
        NotificationManager.error(t("error_data_save - " + error), "");
        // spinner test
        // timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
        // }, 2000);
      });
  };

  return (
    <Container component="main" minWidth="xs">
      <NotificationContainer />
      {formstate && (
        <Paper elevation={5} style={{ padding: 30 }}>
          <Complainwizard activeStep={0} />
          <form id="childform" onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <Grid item sm={12} xs={10}>
                <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>
                  {t("edr_approval_form_title")}
                </TitleTypograpy>
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_approval_title")}
                  id="approval_title"
                  name="approval_title"
                  {...register("approval_title", { required: true })}
                  value={child.complain_title}
                  onChange={handleInputChange}
                  error={errors.approval_title}
                  helperText={
                    errors.approval_title && errors.approval_title.message
                  }
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 1,
                    backgroundColor: "#fff",
                  }}
                  fullWidth
                  label={t("edr_approval_desc")}
                  id="approval_desc"
                  name="approval_desc"
                  multiline
                  minRows={5}
                  maxRows={5}
                  {...register("approval_desc", { required: true })}
                  value={child.complain_desc}
                  onChange={handleInputChange}
                  error={errors.approval_desc}
                  helperText={
                    errors.approval_desc && errors.approval_desc.message
                  }
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    {t("edr_resmode")}
                  </InputLabel>
                  <Select
                    sx={{
                      minWidth: 150,
                      maxHeight: 40,
                      marginLeft: 1,
                      marginTop: 2,
                      marginBottom: 0,
                      "& .MuiInputBase-root": {
                        height: 40,
                        backgroundColor: "#fff",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    defaultValue={resmode}
                    name="resmode"
                    fullWidth
                    id="resmode"
                    {...register("resmode", { required: true })}
                    error={errors.resmode}
                    helperText={errors.resmode && errors.resmode.message}
                    value={resmode}
                    onChange={handleResmodeChange}
                  >
                    {resmodeset.map((x) => {
                      return (
                        <MenuItem value={x} style={{ fontSize: 12 }}>
                          {x.value_en}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    marginTop: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_req_file_ref")}
                  id="file_ref"
                  name="file_ref"
                  {...register("file_ref", { required: true })}
                  value={child.file_ref}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  sx={{
                    marginLeft: 1,
                    marginRight: 10,
                    marginTop: 2,
                    marginBottom: 0,
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  type="date"
                  fullWidth
                  label={t("edr_approval_date")}
                  id="approval_date"
                  name="approval_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("approval_date", { required: true })}
                  defaultValue={formatedDate}
                  value={child.approval_date}
                  onChange={handleInputChange}
                  error={errors.approval_date}
                  helperText={
                    errors.approval_date && errors.approval_date.message
                  }
                />
              </Grid>
              <Grid item sm={12} xs={10}>
                <TitleTypograpy sx={{ marginTop: 2, marginBottom: 2 }}>
                  {t("edr_requester")}
                </TitleTypograpy>
              </Grid>
              <Grid item sm={7} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_complainer_name")}
                  id="requester_name"
                  name="requester_name"
                  {...register("requester_name", { required: true })}
                  value={child.requester_name}
                  onChange={handleInputChange}
                  error={errors.requester_name}
                  helperText={
                    errors.requester_name && errors.requester_name.message
                  }
                />
              </Grid>
              <Grid
                item
                sm={4}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_approval_req__mobile")}
                  id="requester_mobile"
                  name="requester_mobile"
                  {...register("requester_mobile", { required: true })}
                  value={child.requester_mobile}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_approval_req_address")}
                  id="requester_address"
                  name="requester_address"
                  {...register("requester_address", { required: true })}
                  value={child.requester_address}
                  onChange={handleInputChange}
                  error={errors.requester_address}
                  helperText={
                    errors.requester_address && errors.requester_address.message
                  }
                />
              </Grid>
              <Grid
                item
                sm={5}
                xs={12}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                <TextField
                  sx={{
                    marginRight: 10,
                    marginLeft: 1,
                    marginBottom: 2,
                    backgroundColor: "#fff",
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: "#fff",
                    },
                  }}
                  fullWidth
                  label={t("edr_email")}
                  id="requester_email"
                  name="requester_email"
                  {...register("requester_email", { required: true })}
                  value={child.requester_email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item sm={12} xs={10}></Grid>
              <Grid item sm={10} xs={6}></Grid>
              <Grid item sm={2} xs={6}>
                <Button
                  style={{ margin: 10 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  // spinner test
                  sx={buttonSx}
                  disabled={loading}
                >
                  {success ? <CheckIcon /> : <SaveIcon />}

                  {loading && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: purple[900],
                        position: "absoulute",
                        top: "50%",
                        left: "50%",
                        marginTop: "5px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                  {success ? " Saved " : t(" save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
      {uploadform && (
        <Paper elevation={5} style={{ padding: 20 }}>
          <Complainwizard activeStep={1} />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Imageuploader item={item} />
            <h3>{"Reference: EDRPE2024" + item.complain_id}</h3>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={closeComplainFileUpload}
            >
              <Close style={{ marginRight: 10 }} />
              {t("close")}
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
}

export default InquaryForm;
