import React, { useContext, useState } from "react";
import { Card, CardContent, Stack, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import { apidata } from "../../data/data";
import axios from "axios";
import { Store } from "../../utils/Store";
import { useTheme } from "@emotion/react";


function ClosedCountChart() {
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const { token, user_roles } = state.userInfo;
 
  const [allcomplainsnew, setAllcomplainsnew] = useState(0);
  const [allcomplainsopen, setAllcomplainsopen] = useState(0);
  const [allcomplainsclose, setAllcomplainsclose] = useState(0);
  const [allcomplainspoliceassign, setAllcomplainspoliceassign] = useState(0);
  const [allcomplainspoliceopen, setAllcomplainspoliceopen] = useState(0);
  const [allcomplainspoliceclose, setAllcomplainspoliceclose] = useState(0);


  const [edrcomplainsnew, setEdrcomplainsnew] = useState(0);
  const [edrcomplainsopen, setEdrcomplainsopen] = useState(0);
  const [edrcomplainsclose, setEdrcomplainsclose] = useState(0);
  const [edrcomplainspoliceassign, setEdrcomplainspoliceassign] = useState(0);
  const [edrcomplainspoliceopen, setEdrcomplainspoliceopen] = useState(0);
  const [edrcomplainspoliceclose, setEdrcomplainspoliceclose] = useState(0);


  const [policecomplainsnew, setPolicecomplainsnew] = useState(0);
  const [policecomplainsclose, setPolicecomplainsclose] = useState(0);
  const [policecomplainspoldivnew, setPolicecomplainspoldivnew] = useState(0);
  const [policecomplainspoldivopen, setPolicecomplainspoldivopen] = useState(0);
  const [policecomplainspoldivclose, setPolicecomplainspoldivclose] =useState(0);
 
  const [allrequestnew, setAllrequestnew] = useState(0);
  const [allrequestopen, setAllrequestopen] = useState(0);
  const [allrequestclose, setAllrequestclose] = useState(0);


  const data = [
    { name: "NEW", value: allcomplainsnew },
    { name: "OPEN", value: allcomplainsopen },
    { name: "CLOSE", value: allcomplainsclose },
    { name: "POLICE ASSIGN", value: allcomplainspoliceassign },
    { name: "POLICE OPEN", value: allcomplainspoliceopen },
    { name: "POLICE CLOSE", value: allcomplainspoliceclose },
  ];


  const data2 = [
    { name: "NEW", value: edrcomplainsnew },
    { name: "OPEN", value: edrcomplainsopen },
    { name: "CLOSE", value: edrcomplainsclose },
    { name: "POLICE ASSIGN", value: edrcomplainspoliceassign },
    { name: "POLICE OPEN", value: edrcomplainspoliceopen },
    { name: "POLICE CLOSE", value: edrcomplainspoliceclose },
  ];


  const data3 = [
    { name: "NEW", value: policecomplainsnew },
    { name: "CLOSE", value: policecomplainsclose },
    { name: "POLICE DIV NEW", value: policecomplainspoldivnew },
    { name: "POLICE DIV OPEN", value: policecomplainspoldivopen },
    { name: "POLICE DIV CLOSE", value: policecomplainspoldivclose }
  ];


  const data4 = [
    { name: "NEW", value: allrequestnew },
    { name: "OPEN", value: allrequestopen },
    { name: "CLOSE", value: allrequestclose },
  ];
  const COLORS = ["#b091db","#0088FE","#00C49F","#FFBB28","#FF8042","#f58caf"];
  const COLORSEDR = ["#b091db","#0088FE","#00C49F","#FFBB28","#FF8042","#f58caf"];
  const COLORSPolice = ["#b091db","#00C49F","#3795bd","#41b3a2","#a6b37d"];
  const COLORSRequest = ["#b091db","#0088FE","#00C49F"];
 
  useEffect(() => {
    fetchCountSummary();
  }, []);


  const fetchCountSummary = (location) => {
    axios
      .get(apidata.api + "edritem/countsummary", {
        params: { district: location, activerole: user_roles[0].id },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setAllcomplainsnew(response.data.data.all_complain_new);
          setAllcomplainsopen(response.data.data.all_complain_open);
          setAllcomplainsclose(response.data.data.all_complain_close);
          setAllcomplainspoliceassign(response.data.data.all_complain_police_assign);
          setAllcomplainspoliceopen(response.data.data.all_complain_police_open);
          setAllcomplainspoliceclose(response.data.data.all_complain_police_close);
         
          setEdrcomplainsnew(response.data.data.edr_complain_new);
          setEdrcomplainsopen(response.data.data.edr_complain_open);
          setEdrcomplainsclose(response.data.data.edr_complain_close);
          setEdrcomplainspoliceassign(response.data.data.edr_complain_police_assign);
          setEdrcomplainspoliceopen(response.data.data.edr_complain_police_open);
          setEdrcomplainspoliceclose(response.data.data.edr_complain_police_close);
       
          setPolicecomplainsnew(response.data.data.police_complain_new);
          setPolicecomplainsclose(response.data.data.police_complain_close);
          setPolicecomplainspoldivnew(response.data.data.police_complain_police_div_new);
          setPolicecomplainspoldivopen(response.data.data.police_complain_police_div_open);
          setPolicecomplainspoldivclose(response.data.data.police_complain_police_div_close);
         
          setAllrequestnew(response.data.data.all_request_new);
          setAllrequestopen(response.data.data.all_request_open);
          setAllrequestclose(response.data.data.all_request_close);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const chartHeight = isMobile ? 250 : 200;
  const chartWidth = isMobile ? "100%" : "25%";


  return (
    <>
      <Stack
        spacing={3}
        // direction="row" // Change direction to 'row' for horizontal layout
        direction={isMobile ? "column" : "row"}
        sx={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {/* All Complain Chart */}
        <Card sx={{ width: chartWidth }}>
          <CardContent>
            <Typography
              style={{
                fontFamily: "Arial",
                textAlign: "center",
                color: "#8236c9",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {t("All Complain")}
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 30 : 60}
                  fill="#8884d8"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>


        {/* EDR Complain Chart */}
        <Card sx={{ width: chartWidth }}>
          <CardContent>
            <Typography
              style={{
                fontFamily: "Arial",
                textAlign: "center",
                color: "#8236c9",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {t("EDR Complain")}
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={data2}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 30 : 60}
                  fill="#8884d8"
                  label
                >
                  {data2.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSEDR[index % COLORSEDR.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>


        {/* Police Complain Chart */}
        <Card sx={{ width: chartWidth }}>
          <CardContent>
            <Typography
              style={{
                fontFamily: "Arial",
                textAlign: "center",
                color: "#8236c9",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {t("Police Complain")}
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={data3}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 30 : 60}
                  fill="#8884d8"
                  label
                >
                  {data3.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSPolice[index % COLORSPolice.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>


        {/* All Request Chart */}
        <Card sx={{ width: chartWidth }}>
          <CardContent>
            <Typography
              style={{
                fontFamily: "Arial",
                textAlign: "center",
                color: "#8236c9",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              {t("All Request")}
            </Typography>
            <ResponsiveContainer width="100%" height={chartHeight}>
              <PieChart>
                <Pie
                  data={data4}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={isMobile ? 30 : 60}
                  fill="#8884d8"
                  label
                >
                  {data4.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORSRequest[index % COLORSRequest.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}


export default ClosedCountChart;