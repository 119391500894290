import React from 'react';
import { Stack, Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';

function AdminDisplayBox3(props) {
  return (
    <Card
      sx={{  
        // height: '100%',width:'25%' ,  marginLeft: 5
        height: "200px",
      width: "100%",
      marginLeft: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
       }}
    >
      <CardContent>
        <Grid
          
          spacing={2}
          sx={{ justifyContent: 'space-between' }}
        >
           <Grid container alignItems="center">
          <Typography
                color="primary"
                // variant="h5"
                fontSize="1.5rem"
            marginRight= "7px"
              >
                Police Complains
              </Typography>
            <Avatar
              sx={{
                // backgroundColor: '#55e683',
                backgroundColor: "#009900",
                height: 50,
                width: 50
              }}
            >
              <ApartmentIcon />
            </Avatar>
          </Grid>
          <Grid item>
          <Stack>
              
              <Typography
              >
                Today/All
              </Typography>
              <Typography
                color="textPrimary"
                variant="h3"
              >
                {props.day3}/{props.all3}
              </Typography>
            </Stack>
          </Grid>
         
        </Grid>
      </CardContent>
    </Card>
  )
}

export default AdminDisplayBox3